import React from 'react'
import ReactQuill from 'react-quill'
import classNames from 'classnames'
import './Input.css'
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css'

const Quill = React.forwardRef((props, ref) => <ReactQuill ref={ref} theme="snow" {...props} />)

const Input = ({ type = 'text', multiline, transparent, autoSize, className, ...props }, ref) => {
  const Component = type === 'richtext' ? Quill : multiline ? 'textarea' : 'input'
  return (
    <Component
      ref={ref}
      className={classNames(className, 'outline-none block w-full appearance-none placeholder-gray-500', {
        [className]: className && type === 'richtext',
        'py-2 px-4': type !== 'richtext',
        'border border-gray-300 rounded': !transparent && type !== 'richtext',
        'bg-gray-200': props.disabled,
        'h-10': !multiline && type === 'text',
        transparent
      })}
      type={type}
      {...props}
    />
  )
}

export default React.forwardRef(Input)
