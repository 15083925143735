import React, { useMemo, useState } from 'react'
import EllipsisIcon from '@images/ellipsis-v.svg'
import Card, { CardContent, CardFooter } from '@src/components/Card'
import { FormsContext } from '@src/App'
import { List, ListItem } from '@src/components/List'
import Row from '@src/components/Row'
import Button from '@src/components/Button'
import { orderBy } from 'lodash'
import classNames from 'classnames'
import { useOutletContext } from 'react-router-dom'
import useAccount from '@src/hooks/useAccount'
import DashboardForm from '@src/pages/dashboards/DashboardForm'
import DashboardListItem from './DashboardListItem'

const DashboardList = () => {
  const { account } = useAccount()
  const { team, projects } = useOutletContext()
  const dashboards = team ? team.dashboards : account.teams.flatMap((team) => team.dashboards)
  const [showDialog, setShowDialog] = React.useState(false)

  return (
    <Card>
      <CardContent>
        <List>
          {dashboards.length === 0 ? <div className="text-base">There are no dashboards yet.</div> : null}

          {dashboards.map((dashboard) => (
            <DashboardListItem key={dashboard.id} dashboard={dashboard} />
          ))}
        </List>
      </CardContent>
      <CardFooter>
        <Button className="mt-3" variant="primary" onClick={() => setShowDialog(true)}>
          Create a new dashboard
        </Button>
        <DashboardForm
          show={showDialog}
          projects={projects}
          onHide={() => {
            setShowDialog(false)
          }}
          team={team}
        />
      </CardFooter>
    </Card>
  )
}

export default DashboardList
