import React, { forwardRef } from 'react'
import classNames from 'classnames'
import './Card.css'

const Card = ({ className, children, title, ...props }) => (
  <div className={classNames('card', className)} {...props}>
    {title ? <CardTitle>{title}</CardTitle> : null}
    {children}
  </div>
)

export const CardTitle = forwardRef(({ className, ...props }, ref) => (
  <div className={classNames('font-bold text-lg p-4 pb-0', className)} {...props} ref={ref} />
))
export const CardContent = forwardRef(({ className, ...props }, ref) => (
  <div className={classNames('p-6 md:p-8', className)} {...props} ref={ref} />
))

export const CardFooter = ({ children }) => (
  <div className="flex justify-end p-4" style={{ backgroundColor: '#f8fafc' }}>
    {children}
  </div>
)

export default Card
