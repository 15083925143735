// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
}

.backdrop-dark {
  background-color: #000000c2;
}

.backdrop-light {
  background-color: #00000040;
}

.backdrop-enter {
  opacity: 0.01;
}

.backdrop-enter.backdrop-enter-active {
    transition: opacity 400ms ease;
    opacity: 1;
  }

.backdrop-exit {
  opacity: 1;
}

.backdrop-exit.backdrop-exit-active {
    transition: opacity 200ms ease-in;
    opacity: 0.01;
  }
`, "",{"version":3,"sources":["webpack://./app/javascript/src/components/Backdrop.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,WAAW;EACX,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AAMf;;AAJE;IACE,8BAA8B;IAC9B,UAAU;EACZ;;AAGF;EACE,UAAU;AAMZ;;AAJE;IACE,iCAAiC;IACjC,aAAa;EACf","sourcesContent":[".backdrop {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 10;\n  display: flex;\n}\n\n.backdrop-dark {\n  background-color: #000000c2;\n}\n\n.backdrop-light {\n  background-color: #00000040;\n}\n\n.backdrop-enter {\n  opacity: 0.01;\n\n  &.backdrop-enter-active {\n    transition: opacity 400ms ease;\n    opacity: 1;\n  }\n}\n\n.backdrop-exit {\n  opacity: 1;\n\n  &.backdrop-exit-active {\n    transition: opacity 200ms ease-in;\n    opacity: 0.01;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
