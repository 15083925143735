import React, { useState } from 'react'
import Button from '@src/components/Button'
import { CardFooter } from '@src/components/Card'
import useAccount from '@src/hooks/useAccount'
import { useFetch } from 'react-async'
import classNames from 'classnames'
import useCurrencyConvertor from './currency-convertor'

const PAYMENT_LINK_ID = {
  development: 'test_7sI29RbQccNOgNyaEE',
  test: 'test_7sI29RbQccNOgNyaEE',
  production: '9AQeW4fDD5hQaUo7sv'
}[window.environement]

const DASHBOARD_LINK_ID = {
  development: 'test_7sI9CHdTQ7sj24g9AA',
  test: 'test_7sI9CHdTQ7sj24g9AA',
  production: '14kaIBcixgJLcwgfYY'
}[window.environement]

export const buildUrl = (account) => {
  const url = new URL(`https://buy.stripe.com/${PAYMENT_LINK_ID}`)
  url.searchParams.append('prefilled_email', account.email)
  url.searchParams.append('client_reference_id', account.id)
  return url.href
}

export const ValueProp = ({ children }) => (
  <li className="mb-4 flex items-center">
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="check"
      className="text-green-600 w-4 h-4 mr-2"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
      />
    </svg>
    {children}
  </li>
)

const FreeValues = () => (
  <>
    <ValueProp>Unlimited Hill Charts</ValueProp>
    <ValueProp>Unlimited Hill updates</ValueProp>
    <ValueProp>Access to Timelines</ValueProp>
  </>
)

const Pricing = () => {
  const { account } = useAccount()
  const [subscriptionState, setSubscriptionState] = useState(account.subscription.state)
  const { run: destroy } = useFetch(
    '/stripe/subscription',
    { method: 'delete' },
    {
      defer: true,
      onReject: console.error,
      onResolve: () => {
        setSubscriptionState('inactive')
      }
    }
  )

  const locale = navigator.language || navigator.userLanguage || 'en-US'
  const value = 29.99
  const convertedValue = useCurrencyConvertor({ locale, value })
  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR'
  }).format(value)

  return (
    <div className="m-auto">
      <section>
        <div className="grid lg:grid-cols-2 gap-6 lg:gap-x-12">
          <div className="mb-6 lg:mb-0">
            <div
              className={classNames('block rounded-lg bg-white h-full', {
                'shadow-xl border border-gray-200': subscriptionState === 'active'
              })}
            >
              <div className="p-6 border-b border-gray-300 text-center">
                <p className="uppercase mb-4 text-sm font-bold montserrat">
                  <strong>Basic plan</strong>
                </p>
                <h3 className="text-2xl">
                  <strong>Free</strong>
                </h3>
              </div>
              <div className="p-6">
                <ol className="list-inside">
                  <FreeValues />
                  <ValueProp>
                    Team size up to 
                    <span className="font-bold"> 3 members</span>
                  </ValueProp>
                </ol>
              </div>
            </div>
          </div>

          <div className="mb-6 lg:mb-0">
            <div
              className={classNames(
                'block rounded-lg bg-white h-full overflow-hidden shadow-xl border border-green-400 '
              )}
            >
              <div className="p-6 border-b border-gray-300 text-center">
                <p className="uppercase mb-4 text-sm font-bold montserrat">
                  <strong>Enterprise plan</strong>
                </p>
                <h3>
                  {subscriptionState === 'active' ? (
                    <span className="ml-1 p-2 font-bold rounded bg-green-300">Current plan</span>
                  ) : (
                    <span className="text-2xl">
                      <strong>{formattedPrice}</strong>
                      <small className="text-gray-600"> / month per company</small>
                    </span>
                  )}
                </h3>
                {subscriptionState !== 'active' ? (
                  <span className="text-gray-500">{convertedValue ? `(equivalent to ${convertedValue})` : ' '}</span>
                ) : null}
              </div>
              <div className="p-6">
                <ol className="list-inside">
                  <FreeValues />
                  <span className="font-bold">
                    <ValueProp>Unlimited team members</ValueProp>
                    <ValueProp>Dashboards to display status of all your prjects</ValueProp>
                    <ValueProp>Slack notifications on Hill chart updates</ValueProp>
                    <ValueProp>Use webhooks to be notified of Hill chart updates</ValueProp>
                    <ValueProp>Connect your hills to your others tools with the REST API</ValueProp>
                    <ValueProp>Advanced support & feature priorization</ValueProp>
                  </span>
                </ol>
              </div>
              {subscriptionState === 'active' ? (
                <div className="flex justify-end p-4 rounded-b" style={{ backgroundColor: '#f8fafc' }}>
                  <>
                    <Button
                      href={`https://billing.stripe.com/p/login/${DASHBOARD_LINK_ID}`}
                      variant="secondary"
                      component="a"
                      className="mr-2"
                    >
                      Open your invoices
                    </Button>
                    <Button
                      onClick={(event) => {
                        if (
                          window.confirm(
                            'Are you sure that you want to cancel your subscription? You be switched to a free plan at the end of the billing period.'
                          )
                        ) {
                          event.preventDefault()
                          destroy()
                        }
                      }}
                      variant="secondary"
                    >
                      Cancel subscription
                    </Button>
                  </>
                </div>
              ) : (
                <CardFooter>
                  <Button
                    type="button"
                    onClick={() => {
                      window.location.href = buildUrl(account)
                    }}
                    variant="primary"
                    className="m-auto"
                  >
                    SUBSCRIBE
                  </Button>
                </CardFooter>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Pricing
