import React from 'react'
import useAccount from '@src/hooks/useAccount'
import { Link } from 'react-router-dom'
import { canInvite } from '@src/model/team'
import Alert from '@src/components/Alert'

const TeamPlanRequired = ({ team, onSubscribeClick, className }) => {
  const { account } = useAccount()
  const alllowInvite = team && canInvite(team)

  if (alllowInvite) {
    return null
  }

  return (
    <>
      <Alert className={className}>
        With a free plan, you are limited to 3 users per team.
        <br />
        Please ask the owner of this team (
        <span className="font-bold">{team.owner.id === account.id ? 'you' : team.owner.name}</span>) to{' '}
        <Link
          className="underline"
          to="/settings"
          onClick={() => {
            if (onSubscribeClick) onSubscribeClick()
          }}
        >
          subscribe
        </Link>{' '}
        for unlimited teams, projects and many other features.
      </Alert>
    </>
  )
}

export default TeamPlanRequired
