import React from 'react'
import classNames from 'classnames'
import './Popover.css'

export const PopoverContext = React.createContext()

const usePopover = (ref) => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return [show, setShow]
}

const Popover = ({ button, position = 'right', className, children }) => {
  const ref = React.useRef()
  const [show, setShow] = usePopover(ref)

  const onClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setShow(true)
  }

  return (
    <div
      className={classNames('popover', className, {
        [`popover-${position}`]: position
      })}
    >
      {button({ onClick })}
      <PopoverContext.Provider value={setShow}>
        {show && (
          <div ref={ref} className="popover-content">
            {children}
          </div>
        )}
      </PopoverContext.Provider>
    </div>
  )
}

export const PopoverItem = ({ children, icon, onClick }) => {
  const setShow = React.useContext(PopoverContext)
  return (
    <a
      href="#"
      className="popover-item"
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        onClick()
        setShow(false)
      }}
    >
      {icon}
      <div>{children}</div>
    </a>
  )
}

export default Popover
