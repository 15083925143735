import React from 'react'
import useTimeout from '@src/hooks/useTimeout'
import Input from '@src/components/Input'
import './EditableRichText.css'

const EditableRichText = ({ value, onChange, className, onBlur, placeholder }) => {
  const textarea = React.useRef()
  const divRef = React.useRef()
  const [editingMode, setEditingMode] = React.useState(false)
  const setTimeout = useTimeout()

  const onClickOutside = (event) => {
    if (
      editingMode &&
      !textarea.current?.editingArea?.contains(event.target) &&
      !divRef.current?.contains(event.target)
    ) {
      if (onBlur) onBlur()
    }
  }

  React.useEffect(() => {
    const listener = document.body.addEventListener('click', onClickOutside)
    return () => {
      document.body.removeEventListener('click', listener)
    }
  }, [textarea.current])

  return (
    <div className={className}>
      <div className="editable-richtext">
        {editingMode ? (
          <Input
            ref={textarea}
            transparent
            type="richtext"
            defaultValue={value || ''}
            onChange={onChange}
            placeholder={placeholder}
            rows="3"
          />
        ) : (
          <div
            ref={divRef}
            className="editable-richtext-preview free-html"
            dangerouslySetInnerHTML={{
              __html: value || (placeholder ? `<span className="italic">${placeholder}<span/>` : '')
            }}
            onClick={(event) => {
              const link = event.target.closest('a')
              if (link) return
              setEditingMode(true)
              setTimeout(() => {
                textarea.current?.focus()
              }, 100)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default React.forwardRef((props, ref) => <EditableRichText ref={ref} {...props} />)
