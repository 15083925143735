// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quill .ql-header.ql-picker {
    color: var(--font-color);
  }
  .quill .ql-toolbar.ql-snow,
  .quill .ql-container.ql-snow {
    border-color: #e2e8f0;
  }
  .quill > .ql-container > .ql-editor.ql-blank::before {
    color: #a0aec0;
    font-style: normal;
  }
`, "",{"version":3,"sources":["webpack://./app/javascript/src/components/Input.css"],"names":[],"mappings":"AACE;IACE,wBAAwB;EAC1B;EACA;;IAEE,qBAAqB;EACvB;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB","sourcesContent":[".quill {\n  .ql-header.ql-picker {\n    color: var(--font-color);\n  }\n  .ql-toolbar.ql-snow,\n  .ql-container.ql-snow {\n    border-color: #e2e8f0;\n  }\n\n  & > .ql-container > .ql-editor.ql-blank::before {\n    color: #a0aec0;\n    font-style: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
