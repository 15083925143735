import { progressToX, xToProgress } from '@src/components/Hillchart'
import partition from 'lodash/partition'
import groupBy from 'lodash/fp/groupBy'
import flatMap from 'lodash/fp/flatMap'
import orderBy from 'lodash/fp/sortBy'
import flow from 'lodash/fp/flow'

export const SCALE_PRECISION = 10000

export const formatScopes = (tasks, width) =>
  tasks.map((task) => ({
    id: task.id,
    name: task.name,
    color: task.color,
    description: task.description,
    progress: task.progress,
    radius: task.radius,
    x: progressToX(task.progress, width),
    completed: Boolean(task.completed_at),
    project_id: task.project_id,
    account: task.account,
    section_id: task.section_id,
    position: task.position,
    touched: false
  }))

const STACK_OFFSET = 20
const STACKING_THRESHOLD = 20
export const stack = (width, overredTask) =>
  flow(
    groupBy((task) => Math.ceil(task.x / STACKING_THRESHOLD)),
    Object.values,
    flatMap((similarTasks) =>
      similarTasks.map((task, index) => ({
        ...task,
        overred: overredTask === task.id,
        offset: index * STACK_OFFSET
      }))
    ),
    orderBy((task) => {
      if (overredTask && overredTask === task.id) {
        return Infinity
      }
      const isDone = xToProgress(task.x, width) === SCALE_PRECISION
      const zIndex = isDone ? -Infinity : -task.radius
      return zIndex
    })
  )

export const arrayMove = (array, from, to) => {
  array = array.slice()
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])

  return array
}

export const reorderSection = (tasks, task, index) => {
  const sortedTasks = orderBy('position')(tasks)

  return arrayMove(
    sortedTasks,
    sortedTasks.findIndex(({ id }) => id === task.id),
    index
  ).map((task, position) => ({ ...task, position }))
}

export const onDrop = (tasks, task, index) => {
  const cloneTasks = tasks.map((currTask) => (currTask.id === task.id ? task : currTask))
  const [sectionTasks, otherTasks] = partition(cloneTasks, ({ section_id }) => task.section_id === section_id)
  return [...reorderSection(sectionTasks, task, index), ...otherTasks]
}

export const moveSections = (source, destination, run) => (sections) => {
  const newState = arrayMove(sections, source.index, destination.index).map((item, position) => ({ ...item, position }))
  run((init) => ({
    ...init,
    body: JSON.stringify({
      project: {
        sections_attributes: newState.map((section) => ({
          id: section.id,
          position: section.position
        }))
      }
    })
  }))
  return newState
}

const toSectionId = (droppableId) => {
  const integerId = Number(droppableId)
  return integerId === 0 ? null : integerId
}

export const moveTasks = (source, destination, run) => (tasks) => {
  const sourceSectionId = toSectionId(source.droppableId)
  const destSectionId = toSectionId(destination.droppableId)
  const task = tasks.filter((task) => task.section_id === sourceSectionId)[source.index]
  const updatedTask = { ...task, section_id: destSectionId }
  const newState = onDrop(tasks, updatedTask, destination.index)
  run((init) => ({
    ...init,
    body: JSON.stringify({
      project: {
        tasks_attributes: newState.map((task) => ({
          id: task.id,
          section_id: task.section_id,
          position: task.position
        }))
      }
    })
  }))
  return newState
}
