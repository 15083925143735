import React from 'react'
import classNames from 'classnames'

const optionsToArray = (options) => {
  if (!Array.isArray(options)) {
    return Object.keys(options).map((key) => ({
      value: String(key),
      label: options[key]
    }))
  }
  return options.map((entry) => (typeof entry === 'string' ? { value: entry, label: entry } : entry))
}

const Select = ({ type, placeholder, options = [], className, value, ...props }) => (
  <div className="relative w-full">
    <select
      className={classNames(
        className,
        'outline-none border border-gray-300 rounded py-2 px-4 block w-full appearance-none leading-normal',
        { 'text-gray-500': !value && placeholder }
      )}
      value={value}
      {...props}
    >
      {placeholder ? <option value="">{placeholder}</option> : null}
      {optionsToArray(options).map(({ value, label, disabled }) => (
        <option key={value} value={value} disabled={disabled}>
          {label}
        </option>
      ))}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
)

export default React.memo(Select)
