// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hillia-alert {
  background-color: #fff7e1;
  padding: 8px;
  color: #bf9e3f;
  border-radius: 5px;
}

.hillia-alert-main {
  border-radius: 0px;
  background-color: var(--orange);
  color: white;
  border-bottom: 1px solid var(--dark-orange);
}
`, "",{"version":3,"sources":["webpack://./app/javascript/src/components/Alert.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;EAC/B,YAAY;EACZ,2CAA2C;AAC7C","sourcesContent":[".hillia-alert {\n  background-color: #fff7e1;\n  padding: 8px;\n  color: #bf9e3f;\n  border-radius: 5px;\n}\n\n.hillia-alert-main {\n  border-radius: 0px;\n  background-color: var(--orange);\n  color: white;\n  border-bottom: 1px solid var(--dark-orange);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
