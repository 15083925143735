import React from 'react'
import { useFetch } from 'react-async'
import Label from '@src/components/Label'
import Select from '@src/components/Select'
import Button from '@src/components/Button'
import useAccount from '@src/hooks/useAccount'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import groupBy from 'lodash/groupBy'
import Popover, { PopoverItem } from '@src/components/Popover'
import { moveSections, moveTasks } from '@src/model/task'
import useLocalStorage from '@src/hooks/useLocalStorage'
import FilterIcon from '@images/filter.svg'
import CheckIcon from '@images/check.svg'
import useQuery from '@src/hooks/useQuery'
import Section from './Section'
import NewSection from './NewSection'
import CurrentTask from './CurrentTask'

const filterCount = ({ assignee, hideCompleted }) => {
  let filters = 0
  if (assignee) {
    filters++
  }
  if (hideCompleted) {
    filters++
  }
  if (filters === 0) return null

  return `(${filters})`
}

const TaskList = ({ project, tasks, sections = [], setSections, setTasks, hideCompleted, setHideCompleted }) => {
  const { account } = useAccount()
  const newSectionInput = React.useRef()
  const focusState = React.useState()
  const [assignee, setAssignee] = useLocalStorage()
  const [query, setQuery] = useQuery()
  const currentTaskId = query.get('task_id') ? Number(query.get('task_id')) : undefined
  const setCurrentTaskId = (task_id) => {
    setQuery({ task_id })
  }
  const currentTask = tasks.find((task) => task.id === currentTaskId)
  const currentSection = currentTask ? sections.find((section) => section.id === currentTask.section_id) : undefined
  const tasksBySection = {
    ...sections.reduce((acc, section) => ({ ...acc, [section.id]: [] }), {}),
    ...groupBy(tasks, (task) => task.section_id)
  }

  const members = React.useMemo(() => {
    return account.teams.find((team) => team.id === project.team.id).members
  }, [account.teams.length, project.id])

  const [showNewSection, setShowNewSection] = React.useState(false)
  const { run } = useFetch(
    `/projects/${project.id}`,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'put'
    },
    { defer: true, onReject: console.error }
  )

  const addSection = () => {
    setShowNewSection(true)
  }

  const onRemoveSection = (section) => {
    setTasks((tasks) => tasks.filter(({ section_id }) => section_id !== section.id))
    setSections((sections) => sections.filter(({ id }) => id !== section.id))
  }

  const onChange = (attributes) => {
    setTasks((tasks) => tasks.map((task) => (task.id === currentTask.id ? { ...currentTask, ...attributes } : task)))
  }

  const onChangeSection = React.useCallback((updatedSection) => {
    setSections((sections) => sections.map((task) => (task.id === updatedSection.id ? updatedSection : task)))
  }, [])

  const onSelectTask = (task) => {
    setCurrentTaskId(task.id)
  }

  const onDragEnd = ({ source, destination, type }) => {
    if (!destination) {
      return
    }

    if (type === 'sections') {
      setSections(moveSections(source, destination, run))
    } else {
      setTasks(moveTasks(source, destination, run))
    }
  }

  return (
    <div className="flex relative pb-6 mt-6 md:mt-0">
      <div className="flex-grow">
        <div className="mb-3 pr-6 flex justify-end">
          {project.archived ? null : (
            <Button variant="primary" onClick={addSection} className="mr-2">
              Add a scope
            </Button>
          )}
          <Popover
            button={({ onClick }) => (
              <Button variant="secondary" icon={FilterIcon} onClick={onClick}>
                Filter {filterCount({ hideCompleted, assignee })}
              </Button>
            )}
            position="top"
          >
            <div className="p-3">
              <Label className="mb-2">Task status</Label>
              <div className="flex items-center space-x-3">
                <Button
                  variant="outline"
                  icon={hideCompleted ? null : CheckIcon}
                  onClick={() => {
                    setHideCompleted(false)
                  }}
                >
                  All tasks
                </Button>
                <Button
                  variant="outline"
                  icon={hideCompleted ? CheckIcon : null}
                  onClick={() => {
                    setHideCompleted(true)
                  }}
                >
                  Incomplete tasks
                </Button>
              </div>

              <Label className="mt-4 mb-2">Task assignee</Label>
              <Select
                placeholder="Anyone"
                options={members.reduce((acc, elem) => ({ ...acc, [elem.id]: elem.name }), {})}
                value={assignee}
                onChange={(event) => {
                  setAssignee(Number(event.target.value))
                }}
              />
            </div>
          </Popover>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="w-full">
            <Droppable droppableId="project" type="sections">
              {(droppable) => (
                <div ref={droppable.innerRef} style={{ minHeight: 20 }}>
                  {sections.map((section, index) => (
                    <Section
                      key={section.id}
                      index={index}
                      onSelect={onSelectTask}
                      setCurrentTaskId={setCurrentTaskId}
                      autoFocus={section.autoFocus}
                      focusState={focusState}
                      onRemove={onRemoveSection}
                      onChangeSection={onChangeSection}
                      section={section}
                      projectId={project.id}
                      tasks={tasksBySection[section.id]}
                      setTasks={setTasks}
                      members={members}
                      hideCompleted={hideCompleted}
                      readOnly={project.archived}
                      assignee={assignee}
                    />
                  ))}
                  {droppable.placeholder}
                </div>
              )}
            </Droppable>
            {showNewSection ? (
              <NewSection
                project={project}
                usedColors={sections.map((section) => section.color)}
                ref={newSectionInput}
                setSections={setSections}
                onSaved={() => {
                  setShowNewSection(false)
                }}
              />
            ) : null}
          </div>
        </DragDropContext>
      </div>

      <CurrentTask
        task={currentTask}
        section={currentSection}
        onClose={() => setCurrentTaskId(null)}
        onChange={onChange}
        otherTasks={
          currentSection
            ? sections.filter((section) => currentSection.id !== section.id)
            : currentTask
              ? tasks.filter((task) => task.id !== currentTask?.id)
              : null
        }
      />
    </div>
  )
}

export default TaskList
