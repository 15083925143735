import React from 'react'
import Hillchart, { progressToX } from '@src/components/Hillchart'
import EditableRichText from '@src/components/EditableRichText'
import Dialog from '@src/components/Dialog'
import Label from '@src/components/Label'
import throttle from 'lodash/throttle'

const DescriptionField = ({ task, value, onChange: onChangeProp }) => {
  const update = React.useCallback(
    throttle((attributes) => {
      fetch(`/projects/${task.project_id}/tasks/${task.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(attributes)
      })
    }, 2000),
    [task.id]
  )

  const onChange = (value) => {
    onChangeProp(value)
    update({ description: value })
  }

  return <EditableRichText value={value} onChange={onChange} placeholder="Add a description to this task..." />
}

const WIDTH = 350

const CurrentTask = ({ task, section, otherTasks, onChange, onClose }) => (
  <Dialog
    show={!!task}
    padding
    position="right"
    onHide={onClose}
    title={
      <div className="flex items-center">
        <div
          className="rounded-full h-4 w-4 mr-2 flex-shrink-0	"
          style={{ backgroundColor: section?.color || task?.color }}
        />
        <div className="text-lg flex-grow">{task?.name}</div>
      </div>
    }
  >
    {task && (
      <>
        <Label className="mb-1">Assignee</Label>
        <div className="flex items-center">
          <img src={task.account.image} title={task.account.name} className="rounded-full h-6 w-6 mr-2" />
          {task.account.name}
        </div>

        <Label className="mt-6 mb-1">Description</Label>
        <DescriptionField
          task={task}
          value={task.description}
          onChange={(value) => {
            onChange({ description: value })
          }}
        />

        <Label className="mt-6 mb-1">On the Hill</Label>
        <Hillchart
          width={WIDTH}
          tasks={[
            {
              ...(section || task),
              x: progressToX(section?.progress || task.progress, WIDTH),
              name: ''
            },
            ...otherTasks.map((task) => ({
              ...task,
              x: progressToX(task.progress, WIDTH),
              name: '',
              color: '#a0aec063'
            }))
          ]}
        />
      </>
    )}
  </Dialog>
)

export default CurrentTask
