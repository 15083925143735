const enabled = () => {
  try {
    window.localStorage.getItem('foo')
    return true
  } catch (e) {
    return false
  }
}

export const set = (key, value) => {
  if (!enabled()) return true
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    window.localStorage.clear()
  }
  return true
}

export const get = (key) => {
  if (!enabled()) return undefined

  const result = window.localStorage.getItem(key)

  if (!result) return undefined
  return JSON.parse(result)
}

export const fetch = (key, getValue) => {
  const existingValue = get(key)
  if (existingValue === undefined) {
    const newValue = getValue()
    set(key, newValue)
    return newValue
  }
  return existingValue
}

export const clearBrowserData = () => {
  window.localStorage.clear()
  window.sessionStorage.clear()
}

const key = (project) => `p-${project.id}`
export const getMetata = (project) => fetch(key(project), () => ({}))
export const setMetadata = (project, newData) => {
  const metadata = getMetata(project)
  set(key(project), { ...metadata, ...newData })
}
