import React from 'react'

const AccountContext = React.createContext()

const useAccount = () => React.useContext(AccountContext)

export const AccountProvider = ({ children }) => {
  const [account, setAccount] = React.useState(window.current_account)

  return <AccountContext.Provider value={{ account, setAccount }}>{children}</AccountContext.Provider>
}

export default useAccount
