// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-title:hover {
  background-color: var(--task-hover-color);
}

  .section-title:hover .hillia-button-quick-action,
  .section-title:hover .section-title-handle {
    visibility: visible;
  }

.section-title-handle {
  visibility: hidden;
}

.section-name-input {
  background: var(--card-color);
}
`, "",{"version":3,"sources":["webpack://./app/javascript/src/pages/projects/Section.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAM3C;;EAJE;;IAEE,mBAAmB;EACrB;;AAGF;EACE,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".section-title:hover {\n  background-color: var(--task-hover-color);\n\n  .hillia-button-quick-action,\n  .section-title-handle {\n    visibility: visible;\n  }\n}\n\n.section-title-handle {\n  visibility: hidden;\n}\n\n.section-name-input {\n  background: var(--card-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
