import React from 'react'
import Svg from '@images/right.svg'
import './Check.css'

const Check = ({ checked, onClick, style }) => (
  <span className="hillia-check">
    <Svg fill={checked ? 'var(--green)' : 'var(--font-color)'} onClick={onClick} style={style} />
  </span>
)

export default Check
