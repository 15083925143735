import React from 'react'
import { Line, Shape, Group, Text } from 'react-konva'
import useFontFamily from '@src/hooks/useFontFamily'
import { PADDING } from '@src/components/Hillchart'

export const Legend = React.memo(({ height, width }) => {
  const fontFamily = useFontFamily('Roboto')
  const fontSize = Math.ceil(0.0175 * width)
  const fill = '#737c81'

  return (
    <Group>
      <Text
        x={width * 0.2}
        y={height - 15}
        text="FIGURING THINGS OUT"
        fill={fill}
        fontFamily={fontFamily}
        fontSize={fontSize}
      />
      <Text
        x={width * 0.65}
        y={height - 15}
        text="MAKING IT HAPPEN"
        fill={fill}
        fontFamily={fontFamily}
        fontSize={fontSize}
      />
      <Line points={[PADDING, height - 20, width, height - 20]} stroke={fill} strokeWidth={2} />
    </Group>
  )
})

export const VerticalSeparator = React.memo(({ gaussian, height, width }) => {
  const fill = '#737c81'
  return (
    <Shape
      sceneFunc={(context, shape) => {
        context.beginPath()
        for (let i = height - 20; i >= gaussian(width / 2); i -= 3) {
          context.lineTo(width / 2, i)
          context.moveTo(width / 2, i - 5)
          i -= 5
        }
        context.fillStrokeShape(shape)
      }}
      stroke={fill}
      strokeWidth={2}
    />
  )
})

export const Curve = React.memo(({ gaussian, width }) => {
  const fill = '#737c81'
  return (
    <Shape
      sceneFunc={(context, shape) => {
        context.beginPath()
        for (let i = PADDING; i <= width; i++) {
          context.lineTo(i, gaussian(i))
        }
        context.fillStrokeShape(shape)
      }}
      stroke={fill}
      strokeWidth={2}
    />
  )
})
