import React from 'react'
import Button from '@src/components/Button'
import Card, { CardTitle, CardContent, CardFooter } from '@src/components/Card'
import { Routes, Route, Outlet } from 'react-router-dom'
import { List, ListItem } from '@src/components/List'
import useAccount from '@src/hooks/useAccount'
import TeamOptions from '@src/pages/teams/TeamOptions'
import PeopleIcon from '@images/people.svg'
import Table from '@images/table.svg'
import EllipsisIcon from '@images/ellipsis-v.svg'
import ProjectList from '@src/pages/projects/ProjectList'
import { FormsContext } from '@src/App'
import { Tab } from '@src/pages/projects/Tabs'

export const TeamList = () => {
  const { account } = useAccount()
  const { setDrawer } = React.useContext(FormsContext)

  return (
    <>
      <CardContent>
        <List>
          {account.teams.map((team) => (
            <ListItem
              key={team.id}
              to={`/teams/${team.id}`}
              title={`Open ${team.name}`}
              options={
                <TeamOptions
                  team={team}
                  button={({ onClick }) => (
                    <button type="button" title={`Options for ${team.name}`} onClick={onClick} className="p-2">
                      <EllipsisIcon />
                    </button>
                  )}
                />
              }
            >
              <div>
                {team.name}
                <div className="text-gray-500 flex items-center text-sm mt-1">
                  <div className="flex items-center" title={`${team.members.length} members`}>
                    <div className="mr-1">{team.members.length}</div>
                    <PeopleIcon />
                  </div>
                  <div className="flex items-center" title={`${team.projects.length} projects`}>
                    <div className="ml-6 mr-1">{team.projects.length}</div>
                    <Table />
                  </div>
                  <div className="flex items-center">
                    <div className="ml-6 mr-1 rounded border p-1">{team.paid ? 'Enterprise plan' : 'Free plan'}</div>
                  </div>
                </div>
              </div>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardFooter>
        <Button className="mt-3" variant="secondary" onClick={() => setDrawer('team')}>
          Add a new team
        </Button>
      </CardFooter>
    </>
  )
}

const WelcomeLayout = () => {
  const { account } = useAccount()

  return (
    <div className="p-6">
      <div className="m-auto max-w-4xl">
        <h2 className="text-3xl mb-6 montserrat">Welcome</h2>
        <Card>
          <div className="tabs relative pt-3">
            <Tab to="/">Projects</Tab>
            <Tab to="/dashboards">✨ Dashboards</Tab>
            <Tab to="/teams">Teams</Tab>
          </div>
          <div>
            <Outlet context={{ projects: account.projects }} />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default WelcomeLayout
