import React, { useMemo, useState } from 'react'
import EllipsisIcon from '@images/ellipsis-v.svg'
import { StatusColor } from '@src/pages/projects/StatusUpdate'
import ProjectOptions from '@src/pages/projects/ProjectOptions'
import Card, { CardContent, CardFooter } from '@src/components/Card'
import { FormsContext } from '@src/App'
import { List, ListItem } from '@src/components/List'
import Row from '@src/components/Row'
import Button from '@src/components/Button'
import { orderBy } from 'lodash'
import Fuse from 'fuse.js'
import classNames from 'classnames'
import { useOutletContext } from 'react-router-dom'

const FUSE_SETTINGS = {
  keys: [
    { name: 'name', weight: 1 },
    { name: 'description', weight: 0.5 }
  ],
  threshold: 0.4
}

const ProjectList = (props) => {
  const outletContext = useOutletContext()
  const projects = outletContext ? outletContext.projects : props.projects
  const { setDrawer } = React.useContext(FormsContext)
  const [search, setSearch] = useState('')

  const results = useMemo(() => {
    if (!search) return projects
    const fuse = new Fuse(projects, FUSE_SETTINGS)
    return fuse.search(search).map(({ item }) => item)
  }, [search, projects.length])

  const sortedProjects = orderBy(results, 'archived')

  return (
    <Card>
      <CardContent>
        <List>
          {projects.length === 0 ? <div className="text-base">There are no projects yet.</div> : null}
          {projects.length > 10 ? (
            <Row
              label="Search a project"
              className="my-b"
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Name or description of a project"
            />
          ) : null}
          {search ? <div className="font-bold mb-3">{sortedProjects.length} results:</div> : null}
          {sortedProjects.map((project) => (
            <ListItem
              key={project.id}
              to={`/projects/${project.id}`}
              title={`Open ${project.name}`}
              options={
                <ProjectOptions
                  project={project}
                  button={({ onClick }) => (
                    <button type="button" onClick={onClick} className="p-2" title={`Options for ${project.name}`}>
                      <EllipsisIcon />
                    </button>
                  )}
                />
              }
            >
              <div
                className={classNames('flex items-center', {
                  'text-gray-600': project.archived
                })}
              >
                <StatusColor status={project?.last_update?.status} />
                {project.name}
                {project.archived ? ' (archived)' : null}
              </div>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardFooter>
        <Button className="mt-3" variant="primary" onClick={() => setDrawer('project')}>
          Start a new project
        </Button>
      </CardFooter>
    </Card>
  )
}

export default ProjectList
