import { useRef, useEffect } from 'react'

const useTimeout = () => {
  const timer = useRef()
  const setTimer = (callback, delay) => {
    timer.current = setTimeout(callback, delay)
  }
  useEffect(() => () => clearTimeout(timer?.current), [])
  return setTimer
}

export default useTimeout
