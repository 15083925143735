import React, { useEffect } from 'react'
import Card, { CardTitle, CardFooter, CardContent } from '@src/components/Card'
import Button from '@src/components/Button'
import ahoy from 'ahoy.js'
import useAccount from '@src/hooks/useAccount'
import { useFetch } from 'react-async'
import Pricing from './Pricing'

const Settings = () => {
  useEffect(() => {
    ahoy.track('show-settings-page')
  }, [])
  const { account } = useAccount()

  const { run } = useFetch(
    '/account',
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'delete'
    },
    {
      defer: true,
      onResolve: () => {
        window.alert('Your account has been deleted.')
        window.location = 'https://www.hillia.app'
      },
      onReject: () => {
        window.alert('An error occured.')
      }
    }
  )

  return (
    <div className="p-6">
      <div className="m-auto max-w-4xl">
        <h2 className="text-3xl mb-3 montserrat">User settings</h2>
        <h2 className="text-xl mb-3 montserrat">Subscription plan</h2>
        <Pricing />

        <div className="mt-12">
          <Card>
            <CardTitle>Your Hillia account</CardTitle>
            <CardContent>
              {account.subscription.state === 'active' ? (
                <div className="mb-3">You must first cancel your subscription in order to delete your account</div>
              ) : null}
            </CardContent>

            <CardFooter>
              <Button
                variant="orange"
                disabled={account.subscription.state === 'active'}
                onClick={() => {
                  if (
                    window.confirm(
                      'This operation will delete your account permanently. It is impossible to recover the data. Are you sure?'
                    )
                  ) {
                    run()
                  }
                }}
              >
                Delete my account
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Settings
