import React from 'react'
import classNames from 'classnames'
import './Alert.css'
import CloseIcon from '@images/close.svg'

const Alert = ({ variant, className, onDismiss, children, ...rest }) => (
  <div
    className={classNames('hillia-alert', 'flex items-baseline', className, {
      [`hillia-alert-${variant}`]: !!variant
    })}
    {...rest}
  >
    <div className="flex-grow">{children}</div>
    {onDismiss && (
      <button type="button" onClick={onDismiss} title="Dimiss" className="mr-6">
        <CloseIcon />
      </button>
    )}
  </div>
)

export default Alert
