import React, { useEffect, useState } from 'react'
import { useFetch } from 'react-async'
import { progressToX } from '@src/components/Hillchart'
import { keyBy, mapValues, pickBy, omitBy, values } from 'lodash'
import consumer from '../channels/consumer'

const ProjectContext = React.createContext()

export const ProjectProvider = ({ children, uuid, id }) => {
  const fetchData = useFetch(id ? `/projects/${id}.json` : `/token_projects/${uuid}.json`, {
    headers: { accept: 'application/json' }
  })

  const [project, setProject] = useState()
  const [width, setWidth] = useState()
  const [touched, setTouched] = useState(false)

  const updateScopes = (remoteSections) => {
    setTouched(false)
    setProject((project) => {
      const localSections = project.sections
      const keyedLocal = keyBy(localSections, 'id')
      const keyedRemote = keyBy(remoteSections, 'id')

      // Update or add sections from remote, focusing on the 'x' attribute
      const updatedLocal = mapValues(keyedLocal, (section, id) => {
        if (keyedRemote[id]) {
          return { ...section, x: progressToX(keyedRemote[id].progress, width) }
        }
        return section
      })

      // Filter out sections not present in the remote array
      const filteredUpdatedLocal = pickBy(updatedLocal, (_, key) => keyedRemote.hasOwnProperty(key))

      // Include new sections from remote that are not in local
      const finalSections = {
        ...filteredUpdatedLocal,
        ...omitBy(keyedRemote, (_, key) => keyedLocal.hasOwnProperty(key))
      }

      // Convert the final object back to an array
      const reconciledSections = values(finalSections)

      return {
        ...project,
        sections: reconciledSections
      }
    })
  }

  useEffect(() => {
    if (!width) {
      return () => {}
    }

    const subscription = consumer.subscriptions.create(
      { channel: 'ProjectsChannel', project_id: id, token: uuid },
      {
        connected() {
          console.log('connected')
        },
        disconnected() {
          console.log('disconnected')
        },
        received(data) {
          console.log('received', data.sections)
          updateScopes(data.sections)
        }
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [width])

  useEffect(() => {
    if (!fetchData.data?.id) {
      return
    }
    setProject(fetchData.data)
  }, [fetchData?.data?.id])

  if (fetchData.error) {
    window.location.href = '/404'
    return null
  }

  if (!project) {
    return null
  }

  return (
    <ProjectContext.Provider value={{ project, setProject, width, setWidth, touched, setTouched }}>
      {children}
    </ProjectContext.Provider>
  )
}

const useProject = () => React.useContext(ProjectContext)

export default useProject
