export const getSlackUrl = (project) =>
  `https://slack.com/oauth/v2/authorize?scope=chat:write,channels:read,groups:read,chat:write.public,files:write&client_id=${window.slack_client_id}&state=${project.id}&redirect_uri=${window.location.origin}/slack_oauths/new`

export const STATUSES = {
  on_track: 'On Track',
  at_risk: 'At Risk',
  off_track: 'Off Track',
  on_hold: 'On Hold'
}

const STATUS_COLORS = {
  on_track: '#00bf9c',
  at_risk: '#f2a100',
  off_track: '#ed4758',
  on_hold: '#008ce3'
}

export const getStatusColor = (status) => STATUS_COLORS[status]
