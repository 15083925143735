import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { pickBy, identity } from 'lodash'

const useQuery = () => {
  const query = new URLSearchParams(useLocation().search)
  const navigate = useNavigate()
  const setQuery = useCallback(
    (query) => {
      const queryString = new URLSearchParams(pickBy(query, identity)).toString()
      navigate({ search: `?${queryString}` })
    },
    [navigate]
  )
  return [query, setQuery]
}

export default useQuery
