import React from 'react'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import CSSTransition from 'react-transition-group/CSSTransition'
import './Backdrop.css'

export const Backdrop = ({ show, onClick, variant }) => (
  <TransitionGroup component="div">
    {show ? (
      <CSSTransition classNames="backdrop" timeout={{ enter: 300, exit: 400 }}>
        <div className={`backdrop backdrop-${variant}`} onClick={onClick} />
      </CSSTransition>
    ) : null}
  </TransitionGroup>
)

Backdrop.defaultProps = {
  variant: 'light'
}

export default React.memo(Backdrop)
