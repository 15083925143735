// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hillia-list-item:hover {
  background-color: var(--card-footer-bg-color);
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./app/javascript/src/components/List.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;EAC7C,kBAAkB;AACpB","sourcesContent":[".hillia-list-item:hover {\n  background-color: var(--card-footer-bg-color);\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
