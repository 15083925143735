import React from 'react'
import HillChart, { progressToX } from '@src/components/Hillchart'
import Card from '@src/components/Card'
import { SCALE_PRECISION, formatScopes } from '@src/model/task'
import { useMediaQuery } from 'react-responsive'
import './Homepage.css'

const MOVE_DURATION = 2000
const MOVE_INTERVAL = 4000
const STEP_INTERVAL = 5
const STEP_PIXEL = 0.175

const DynamicHillchart = () => {
  const isMediumScreen = useMediaQuery({ query: '(min-width: 768px)' })
  const WIDTH = isMediumScreen ? 400 : 300
  const HEIGHT = WIDTH * 0.625
  const TASKS = formatScopes(
    [
      {
        id: 1,
        name: 'Signin form',
        color: '#3ecf8e',
        progress: 2000,
        radius: 13,
        completed: false
      },
      {
        id: 2,
        name: 'GraphQL API',
        color: '#435f71',
        progress: 900,
        radius: 9,
        completed: false
      },
      {
        id: 3,
        name: 'Data migration',
        color: '#ff7d4b',
        progress: 7000,
        radius: 13,
        completed: false
      }
    ],
    WIDTH
  )

  const [tasks, setTasks] = React.useState(TASKS)

  React.useEffect(() => {
    let previousInterval
    setInterval(() => {
      if (previousInterval) {
        clearInterval(previousInterval)
      }
    }, MOVE_DURATION)

    setInterval(() => {
      const randomTask = TASKS[Math.floor(Math.random() * TASKS.length)]
      const maxWidth = progressToX(SCALE_PRECISION, WIDTH)
      previousInterval = setInterval(() => {
        setTasks((tasks) => {
          return tasks.map((currentTask) => {
            if (currentTask.id !== randomTask.id) {
              return currentTask
            }
            if (currentTask.x > maxWidth) {
              clearInterval(previousInterval)
              return currentTask
            }
            return { ...currentTask, x: currentTask.x + STEP_PIXEL }
          })
        })
      }, STEP_INTERVAL)
    }, MOVE_INTERVAL)
  }, [])

  return (
    <Card className="dynamic-hillchart" style={{ width: WIDTH }}>
      <HillChart tasks={tasks} width={WIDTH} height={HEIGHT} />
    </Card>
  )
}

export default DynamicHillchart
