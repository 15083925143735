import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@src/components/Button'
import EllipsisIcon from '@images/ellipsis-v.svg'
import Popover, { PopoverItem } from '@src/components/Popover'
import EnterprisePill from '@src/pages/projects/EnterprisePill'
import Edit from '@images/edit.svg'
import DashboardImg from '@images/dashboard.svg'

export default function DashboardHeader({ dash, team, onEdit }) {
  return (
    <div className="mb-6">
      <Link to={`/teams/${team.id}`} className="underline mb-3 block">
        {team.name}
      </Link>
      <div className="flex space-x-2 items-center">
        <DashboardImg />
        <h2 className="text-3xl montserrat">{dash.name}</h2>
        {team.paid ? (
          <Popover
            button={({ onClick }) => (
              <Button
                variant="tertiary"
                title="Actions"
                className="rounded-full p-2"
                style={{ height: 32, width: 32, padding: 0 }}
                onClick={onClick}
              >
                <EllipsisIcon />
              </Button>
            )}
          >
            <PopoverItem
              icon={<Edit />}
              onClick={() => {
                onEdit()
              }}
            >
              Edit
            </PopoverItem>
          </Popover>
        ) : null}
      </div>
      {team.paid ? null : (
        <div className="mt-3">
          <EnterprisePill />
        </div>
      )}
    </div>
  )
}
