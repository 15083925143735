// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editable-richtext .ql-editor {
    background-color: white;
  }
  .editable-richtext .editable-richtext-preview {
    border: 1px solid transparent;
    border-radius: 5px;
    margin: 0 -0.5rem;
    padding: 0.5rem;
    cursor: text;
    background-color: white;
    border: 1px solid var(--border-color);
  }
  .editable-richtext .editable-richtext-preview:hover {
      border: 1px solid var(--button-border-color);
      cursor: pointer;
    }

.free-html a {
    text-decoration: underline;
  }

.free-html li {
    margin-left: 30px;
  }

.free-html ul > li {
    list-style: disc;
  }

.free-html ol > li {
    list-style: decimal;
  }

.free-html h1,
  .free-html h2,
  .free-html h3 {
    font-weight: bold;
  }

.free-html h1 {
    font-size: 2em;
  }

.free-html h2 {
    font-size: 1.5em;
  }

.free-html h3 {
    font-size: 1.17em;
  }
`, "",{"version":3,"sources":["webpack://./app/javascript/src/components/EditableRichText.css"],"names":[],"mappings":"AACE;IACE,uBAAuB;EACzB;EAEA;IACE,6BAA6B;IAC7B,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,uBAAuB;IACvB,qCAAqC;EAMvC;EAJE;MACE,4CAA4C;MAC5C,eAAe;IACjB;;AAKF;IACE,0BAA0B;EAC5B;;AAEA;IACE,iBAAiB;EACnB;;AAEA;IACE,gBAAgB;EAClB;;AAEA;IACE,mBAAmB;EACrB;;AAEA;;;IAGE,iBAAiB;EACnB;;AAEA;IACE,cAAc;EAChB;;AAEA;IACE,gBAAgB;EAClB;;AAEA;IACE,iBAAiB;EACnB","sourcesContent":[".editable-richtext {\n  .ql-editor {\n    background-color: white;\n  }\n\n  .editable-richtext-preview {\n    border: 1px solid transparent;\n    border-radius: 5px;\n    margin: 0 -0.5rem;\n    padding: 0.5rem;\n    cursor: text;\n    background-color: white;\n    border: 1px solid var(--border-color);\n\n    &:hover {\n      border: 1px solid var(--button-border-color);\n      cursor: pointer;\n    }\n  }\n}\n\n.free-html {\n  a {\n    text-decoration: underline;\n  }\n\n  li {\n    margin-left: 30px;\n  }\n\n  ul > li {\n    list-style: disc;\n  }\n\n  ol > li {\n    list-style: decimal;\n  }\n\n  h1,\n  h2,\n  h3 {\n    font-weight: bold;\n  }\n\n  h1 {\n    font-size: 2em;\n  }\n\n  h2 {\n    font-size: 1.5em;\n  }\n\n  h3 {\n    font-size: 1.17em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
