import React from 'react'
import classNames from 'classnames'
import CloseIcon from '@images/close.svg'
import Logo from '@images/logo.png'
import './Sidebar.css'

const SidebarContext = React.createContext()

const Sidebar = ({ children, onClose, docked }) => {
  return (
    <aside className={classNames('sidebar', { 'sidebar-docked': docked })}>
      <SidebarContext.Provider value={onClose}>
        <div className="px-3 my-3 cursor-pointer flex justify-between">
          <div className="py-2">
            <img src={Logo} style={{ width: 60 }} />
          </div>
          <a
            href="#"
            className="md:hidden"
            title="Close menu"
            onClick={(event) => {
              event.preventDefault()
              onClose()
            }}
          >
            <CloseIcon fill="white" className="mx-3" />
          </a>
        </div>
        <div className="sidebar-children flex flex-col justify-center text-white flex-grow pb-3">{children}</div>
      </SidebarContext.Provider>
    </aside>
  )
}

export default Sidebar
