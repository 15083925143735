import React from 'react'
import { useFetch } from 'react-async'
import Row from '@src/components/Row'
import Button from '@src/components/Button'
import Popover, { PopoverItem } from '@src/components/Popover'
import Dialog from '@src/components/Dialog'
import EllipsisIcon from '@images/ellipsis-v.svg'
import useProject from '@src/hooks/useProject'
import { STATUSES } from '@src/model/project'
import Edit from '@images/edit.svg'
import Trash from '@images/trash.svg'

const EditSnapshotDialog = ({ show, snapshot, onUpdate, onHide }) => {
  const [comment, setComment] = React.useState(snapshot.comment)
  const [status, setStatus] = React.useState(snapshot.status)
  const { run } = useFetch(
    snapshot.url,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'put'
    },
    { defer: true, onReject: console.error }
  )

  const onSubmit = (event) => {
    event.preventDefault()
    onUpdate({ ...snapshot, comment, status })
    run((init) => ({
      ...init,
      body: JSON.stringify({ project_snapshot: { comment, status } })
    }))
    onHide()
  }

  return (
    <Dialog position="right" show={show} onHide={onHide} title={<span className="text-2xl">Edit timeline entry</span>}>
      <form onSubmit={onSubmit} className="p-3">
        <Row
          label="Status"
          name="status"
          type="select"
          placeholder="Set a status"
          autoComplete="off"
          options={STATUSES}
          value={status}
          onChange={(event) => setStatus(event.target.value)}
        />
        <Row
          label="Description"
          name="comment"
          type="richtext"
          required
          autoComplete="off"
          defaultValue={comment}
          onChange={(value) => setComment(value)}
        />
        <div className="flex justify-end">
          <Button
            onClick={(event) => {
              event.preventDefault()
              onHide()
            }}
            className="mr-3"
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={!comment && !status}>
            Save Project
          </Button>
        </div>
      </form>
    </Dialog>
  )
}

const SnapshotOptions = ({ snapshot, onDestroy, onUpdate }) => {
  const [showEdit, setShowEdit] = React.useState(false)
  const { project } = useProject()
  const { run } = useFetch(
    snapshot.url,
    { method: 'delete' },
    {
      defer: true,
      onResolve: () => {
        onDestroy()
      },
      onReject: console.error
    }
  )

  const onHide = () => {
    setShowEdit(false)
  }

  if (project.archived) {
    return null
  }

  return (
    <div className="absolute" style={{ top: 16, right: 16 }}>
      <Popover
        position="left"
        button={({ onClick }) => (
          <button type="button" onClick={onClick} className="p-2" title="Open snapshot options">
            <EllipsisIcon />
          </button>
        )}
      >
        <PopoverItem icon={<Edit />} onClick={() => setShowEdit(true)}>
          Edit snapshot
        </PopoverItem>
        <PopoverItem icon={<Trash />} onClick={() => run()}>
          Delete snapshot
        </PopoverItem>
      </Popover>

      <EditSnapshotDialog snapshot={snapshot} show={showEdit} onHide={onHide} onUpdate={onUpdate} />
    </div>
  )
}

export default SnapshotOptions
