import React from 'react'
import { useFetch } from 'react-async'
import { getRandomColor } from '@src/pages/projects/NewTask'
import SavingContext from '@src/hooks/SavingContext'
import { uniqBy } from 'lodash'

const DEFAULT_NAME = ''
const NewSection = React.forwardRef((props, ref) => {
  const { project, setSections, onSaved, usedColors } = props
  const [, setSaved] = React.useContext(SavingContext)
  const [name, setName] = React.useState(DEFAULT_NAME)
  const { run } = useFetch(
    `/projects/${project.id}/sections`,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'post'
    },
    {
      defer: true,
      onReject: console.error,
      onResolve: (section) => {
        onSaved()
        setSections((sections) =>
          uniqBy([...sections, { ...section, name: section.name, x: 0, autoFocus: true }], 'id')
        )
        if (section.changed_saved) {
          setSaved(true)
        }
      }
    }
  )

  return (
    <form
      className="flex items-center w-full"
      onSubmit={(event) => {
        event.preventDefault()
        setName(DEFAULT_NAME)
        run((init) => ({
          ...init,
          body: JSON.stringify({
            section: { name, color: getRandomColor(usedColors) }
          })
        }))
      }}
    >
      <input
        ref={ref}
        autoFocus
        placeholder="New scope"
        className="w-full py-3 font-bold text-lg outline-none bg-transparent"
        style={{ marginLeft: 36 }}
        onChange={(event) => setName(event.target.value)}
        value={name}
      />
      <div className="task-list-item-keyboard-help">Press enter to save</div>
    </form>
  )
})

export default NewSection
