// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hillia-check {
  cursor: pointer;
}

  .hillia-check svg {
    margin: auto;
    transition: fill 200ms;
  }
`, "",{"version":3,"sources":["webpack://./app/javascript/src/components/Check.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AAMjB;;EAJE;IACE,YAAY;IACZ,sBAAsB;EACxB","sourcesContent":[".hillia-check {\n  cursor: pointer;\n\n  svg {\n    margin: auto;\n    transition: fill 200ms;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
