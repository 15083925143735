import React from 'react'
import ahoy from 'ahoy.js'
import Dialog from '@src/components/Dialog'
import Row from '@src/components/Row'
import Button from '@src/components/Button'
import { useFetch } from 'react-async'
import { useNavigate } from 'react-router-dom'
import useAccount from '@src/hooks/useAccount'
import ProjectIcon from '@images/project.svg'

const ProjectForm = ({ show, onHide, project, onChange }) => {
  const navigate = useNavigate()
  const { account, setAccount } = useAccount()
  const [name, setName] = React.useState(project?.name || '')
  const creation = !project
  const [teamId, setTeamId] = React.useState(project?.team_id || account.teams[0]?.id)
  const { run } = useFetch(
    project ? `/projects/${project.id}` : '/projects',
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: project ? 'put' : 'post'
    },
    {
      defer: true,
      onResolve: (updatedProject) => {
        if (creation) {
          ahoy.track('project-create')
          setAccount((account) => ({
            ...account,
            projects: [...account.projects, updatedProject]
          }))
          setName('')
          navigate(`/projects/${updatedProject.id}`)
        } else {
          setAccount((account) => ({
            ...account,
            projects: account.projects.map((currProject) =>
              currProject.id === project.id ? updatedProject : currProject
            )
          }))
          if (onChange) {
            onChange(updatedProject)
          }
          if (updatedProject.team_id !== project.team_id) {
            window.location.reload() // I am lazy
          }
        }
      },
      onReject: console.error
    }
  )

  const onSubmit = (event) => {
    event.preventDefault()
    onHide()
    if (creation) {
      run((init) => ({
        ...init,
        body: JSON.stringify({ name, team_id: teamId })
      }))
    } else {
      run((init) => ({
        ...init,
        body: JSON.stringify({ name, team_id: teamId })
      }))
    }
  }

  return (
    <Dialog
      show={show}
      onHide={onHide}
      title={
        <h2 className="flex items-center">
          <ProjectIcon />
          <span className="pl-3 text-2xl">{project ? `Edit ${project.name}` : 'Create a new project'}</span>
        </h2>
      }
      position="right"
      padding
    >
      <form onSubmit={onSubmit} autoComplete="off">
        <input autoComplete="false" name="hidden" type="text" className="hidden" />
        <Row
          required
          label="Team"
          name="teamId"
          type="select"
          placeholder=" "
          options={account.teams.map(({ id, name }) => ({
            label: name,
            value: id
          }))}
          value={teamId}
          onChange={(event) => setTeamId(event.target.value)}
        />

        <Row
          label="Name of the project"
          name="name"
          autoFocus
          autoComplete="off"
          required
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        <div className="flex justify-end">
          <Button
            onClick={(event) => {
              event.preventDefault()
              onHide()
            }}
            className="mr-3"
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={!name || !teamId}>
            Save Project
          </Button>
        </div>
      </form>
    </Dialog>
  )
}

export default ProjectForm
