import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useFetch } from 'react-async'
import useAccount from '@src/hooks/useAccount'
import DashboardProject from './DashboardProject'
import Card, { CardContent, CardTitle } from '@src/components/Card'
import Button from '@src/components/Button'
import DashboardHeader from './DashboardHeader'
import DashboardForm from './DashboardForm'

export default function Dashboard(props) {
  const dashboardId = useParams().dashboard_id
  const { account } = useAccount()
  const dashboards = account.teams.flatMap((team) => team.dashboards)
  const dashboard = dashboards.find((dash) => dash.id === Number(dashboardId))
  const team = account.teams.find((team) => team.dashboards.some((dash) => dash.id == dashboard.id))
  const projects = account.projects.filter((project) => project.team_id === team.id)
  const [showDialog, setShowDialog] = React.useState(false)
  const fetchData = useFetch(`/teams/${dashboard.team_id}/dashboards/${dashboard.id}.json`, {
    headers: { accept: 'application/json' }
  })
  if (!fetchData.isResolved) {
    return null
  }
  const dash = fetchData.data

  return (
    <div className="flex justify-center">
      <div className="p-6 max-w-6xl w-full">
        <DashboardForm
          dashboard={dashboard}
          show={showDialog}
          projects={projects}
          onHide={() => {
            setShowDialog(false)
          }}
          team={team}
        />
        <DashboardHeader
          dash={dash}
          team={team}
          onEdit={() => {
            setShowDialog(true)
          }}
        />
        <div className="flex flex-col space-y-6">
          {dash.project_ids.map((projectId) => (
            <DashboardProject key={projectId} projectId={projectId} />
          ))}
          <Card className="border-dashed border-4">
            <CardContent className="flex items-center justify-center">
              <Button
                variant="outline"
                disabled={!team.paid}
                onClick={() => {
                  setShowDialog(true)
                }}
              >
                Add a project to the dashboard
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}
