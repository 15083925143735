import React from 'react'
import Select from '@src/components/Select'
import Label from '@src/components/Label'
import DeleteIcon from '@images/trash.svg'
import { List, ListItem } from '@src/components/List'
import useAccount from '@src/hooks/useAccount'
import Button from '@src/components/Button'

const ProjectSelector = ({ projects, projectIds, onSelect }) => {
  const selectableProjects = projects.filter((project) => !project.archived && !projectIds.includes(project.id))
  const options = selectableProjects.reduce((acc, elem) => ({ ...acc, [elem.id]: elem.name }), {})
  const [projectId, setProjectId] = React.useState('')
  return (
    <div className="flex space-x-3 mb-6 mt-3">
      <Select
        name="projects"
        options={options}
        placeholder="Select a project"
        value={projectId}
        onChange={(event) => {
          setProjectId(event.target.value)
        }}
      />
      <Button
        type="Add project to dashboard"
        variant="outline"
        disabled={!projectId}
        onClick={() => {
          event.preventDefault()
          onSelect(Number(projectId))
          setProjectId('')
        }}
      >
        Add to dashboard
      </Button>
    </div>
  )
}

export default function DashboardFormProjects({ team, projectIds, onChange }) {
  const { account } = useAccount()
  const projects = team ? account.projects.filter((project) => project.team_id === team.id) : []
  const projectsByName = projects.reduce((acc, elem) => ({ [elem.id]: elem.name, ...acc }), {})

  return (
    <div>
      <Label htmlFor="projects" name="projects" className="mb-2">
        Projects
      </Label>

      <List>
        {projectIds.map((projectId) => (
          <ListItem key={projectId}>
            <div className="flex items-center space-x-3">
              <div className="flex-grow">{projectsByName[projectId]}</div>
              <Button
                title="Remove from dashboard"
                onClick={() => {
                  onChange((ids) => ids.filter((id) => id !== projectId))
                }}
              >
                <DeleteIcon />
              </Button>
            </div>
          </ListItem>
        ))}
      </List>
      <ProjectSelector
        projects={projects}
        projectIds={projectIds}
        onSelect={(projectId) => {
          onChange((projectIds) => [...projectIds, projectId])
        }}
      />
    </div>
  )
}
