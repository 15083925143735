import React from 'react'

const useMessageBeforeUnload = (touched) => {
  React.useEffect(() => {
    if (!touched) return undefined
    const confirmExit = () => 'You have unsaved changes, are you sure you want to leave?'
    window.onbeforeunload = confirmExit
    return () => {
      window.onbeforeunload = undefined
    }
  }, [touched])
}

export default useMessageBeforeUnload
