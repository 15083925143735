import React, { useState, useEffect } from 'react'
import ahoy from 'ahoy.js'
import Select from '@src/components/Select'
import Label from '@src/components/Label'
import Input from '@src/components/Input'
import Button from '@src/components/Button'
import { xToProgress } from '@src/components/Hillchart'
import SlackIcon from '@images/slack.svg'
import useAccount from '@src/hooks/useAccount'
import dayjs from 'dayjs'
import { StatusColor } from '@src/pages/projects/StatusUpdate'
import { getSlackUrl, STATUSES } from '@src/model/project'
import './HistoryEntry.css'
import 'canvas-toBlob'

const stripHtml = (string) => string.replace(/<[^>]*>?/gm, '')

const post = ({ project, canvas, comment, status, scopes }) =>
  new Promise((resolve) => {
    canvas.toBlob((blob) => {
      const formData = new FormData()
      formData.append('project[hillchart_img]', blob, 'chart')
      formData.append('project[comment]', comment)
      formData.append('project[status]', status)
      formData.append('history_entry', 'true')
      scopes.forEach((scope) => {
        Object.entries(scope).forEach(([name, value]) => {
          formData.append(`project[sections_attributes][${scope.id}][${name}]`, value)
        })
      })
      fetch(`/projects/${project.id}`, { body: formData, method: 'put' }).then((response) => {
        resolve(response.json())
      })
    })
  })

const SlackMessage = ({ children }) => (
  <div className="flex items-center mt-3" style={{ minHeight: 19 }}>
    {children && <SlackIcon height="16" width="16" className="mr-3" />}
    {children}
  </div>
)

const SlackStatus = ({ project, notifiable }) => {
  if (project.slack_channel) {
    return <SlackMessage>{notifiable ? 'Your team will be notified on Slack.' : null}</SlackMessage>
  }

  if (project.slack_enabled) {
    return (
      <SlackMessage>
        <a
          className="underline color-dark-orange"
          href={`/projects/${project.id}/slack`}
          target="_blank"
          onClick={() => {
            ahoy.track('status-set-slack-channel', { project_id: project.id })
          }}
        >
          You must configure a Slack channel to send project updates.
        </a>
      </SlackMessage>
    )
  }

  return (
    <SlackMessage>
      <a
        className="underline color-green"
        href={getSlackUrl(project)}
        target="_blank"
        onClick={() => {
          ahoy.track('status-init-slack', { project_id: project.id })
        }}
      >
        Enable Slack project updates.
      </a>
    </SlackMessage>
  )
}

const HistoryEntry = ({ project, snapshots, sections, onSave, onCancel, width, hillchartRef }) => {
  const form = React.useRef()
  const { account } = useAccount()
  const [statusUpdate, setStatusUpdate] = useState('')
  const lastSnapshot = snapshots[0]
  const [status, setStatus] = useState(lastSnapshot?.status || '')

  const save = async (statusUpdate, status) => {
    const scopes = sections
      .filter((section) => section.touched)
      .map((section) => ({
        id: section.id,
        progress: xToProgress(section.x, width)
      }))

    const canvas = hillchartRef.current.content.querySelector('canvas')
    const updatedProject = await post({
      project,
      canvas,
      scopes,
      status,
      comment: statusUpdate
    })
    setStatusUpdate('')
    onSave(updatedProject)
  }

  // TODO make it work with react-router data router Prompt or useBeforeUnload
  // useBeforeUnload('You have unsaved changes, are you sure you want to leave?')

  return (
    <form
      ref={form}
      onSubmit={(event) => {
        event.preventDefault()
        save(statusUpdate, status)
      }}
    >
      <div>
        <div className="flex items-center mb-3">
          <img src={account.image} title={account.name} className="rounded-full h-8 w-8 mr-3" />
          <div>
            <div className="font-bold mr-3">{account.name}</div>
            <div className="text-gray-500">{dayjs().format('MMM D')}</div>
          </div>
        </div>
        <div className="flex items-center mb-3">
          <Label className="block" style={{ width: 100 }}>
            Status
          </Label>
          {status ? <StatusColor status={status} /> : null}
          <Select
            style={{ minWidth: 200 }}
            value={status}
            name="project_status"
            onChange={(event) => setStatus(event.target.value)}
            placeholder="Set a status"
            options={STATUSES}
          />
        </div>

        <div className="flex mb-3">
          <Label className="block" style={{ width: 100 }}>
            Description
          </Label>
          <div className="flex-1">
            <Input
              type="richtext"
              placeholder="How's this project going? What have you accomplished? What's blocked?"
              rows="3"
              className="history-entry-input"
              defaultValue={statusUpdate}
              onChange={(value) => {
                setStatusUpdate(value)
              }}
              onKeyDown={(event) => {
                if (event.keyCode === 13 && event.metaKey) {
                  form.current.dispatchEvent(new Event('submit'))
                }
              }}
            />
            <SlackStatus project={project} notifiable={stripHtml(statusUpdate) || status} />
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-3">
        <Button variant="secondary" onClick={onCancel} className="mr-3">
          Discard changes
        </Button>
        <Button variant="primary" type="submit">
          Save this update
        </Button>
      </div>
    </form>
  )
}

export default HistoryEntry
