import React from 'react'
import ReactDOM from 'react-dom'
import Transition from 'react-transition-group/Transition'
import classNames from 'classnames'
import CloseIcon from '@images/close.svg'
import Backdrop from '@src/components/Backdrop'
import './Dialog.css'

const Dialog = ({ show, onHide, position, children, padding, title, backdrop, appear }) => (
  <Transition in={show} timeout={250} mountOnEnter unmountOnExit appear={appear}>
    {(state) =>
      ReactDOM.createPortal(
        <>
          {backdrop && <Backdrop show={show} uiStyle="light" onClick={onHide} />}
          <div
            className={classNames('dialog', `dialog-${position}`, {
              [`dialog-${state}`]: state
            })}
          >
            <div className="px-8 py-6 flex items-start">
              <div className="text-xl flex-grow overflow-hidden" title={typeof title === 'string' ? title : null}>
                {title}
              </div>
              <button type="button" onClick={onHide} title="Close">
                <CloseIcon />
              </button>
            </div>
            <hr />
            <div className={padding ? 'p-8' : undefined}>{children}</div>
          </div>
        </>,
        document.body
      )
    }
  </Transition>
)

Dialog.defaultProps = {
  position: 'bottom',
  backdrop: true,
  padding: false
}

export default Dialog
