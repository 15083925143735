import React from 'react'
import classNames from 'classnames'

const Label = ({ children, name, className, ...props }) => (
  <label className={classNames('block font-bold', className)} htmlFor={name} {...props}>
    {children}
  </label>
)

export default React.memo(Label)
