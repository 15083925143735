import React, { useEffect, useState } from 'react'
import ahoy from 'ahoy.js'
import { get, set } from '@src/Storage'
import BeerIcon from '@images/beer.svg'
import Dialog from '@src/components/Dialog'
import Input from '@src/components/Input'
import Button from '@src/components/Button'
import dayjs from 'dayjs'
import useAccount from '@src/hooks/useAccount'

const Choice = (props) => <Button className="mr-2 mb-2" variant="outline" {...props} />

const NPSQuestion = ({ onSubmit }) => (
  <>
    <div className="text-lg	bold mb-4">[1/3] How likely is it that you would recommend Hillia to a colleague?</div>
    <div className="flex flex-wrap">
      <Choice onClick={onSubmit(0)}>0</Choice>
      <Choice onClick={onSubmit(1)}>1</Choice>
      <Choice onClick={onSubmit(2)}>2</Choice>
      <Choice onClick={onSubmit(3)}>3</Choice>
      <Choice onClick={onSubmit(4)}>4</Choice>
      <Choice onClick={onSubmit(5)}>5</Choice>
      <Choice onClick={onSubmit(6)}>6</Choice>
      <Choice onClick={onSubmit(7)}>7</Choice>
      <Choice onClick={onSubmit(8)}>8</Choice>
      <Choice onClick={onSubmit(9)}>9</Choice>
      <Choice onClick={onSubmit(10)}>10</Choice>
    </div>
  </>
)

const Radio = ({ label, name, checked, onChange }) => (
  <div className="flex items-center">
    <input
      type="radio"
      className="mr-2"
      id={label}
      name={name}
      value={label}
      checked={checked}
      onChange={() => {
        onChange(label)
      }}
    />
    <label htmlFor={label} className="text-base">
      {label}
    </label>
  </div>
)

const IntegrationQuestion = ({ integration, setIntegration, onSubmit }) => {
  const integrations = ['Jira', 'Asana', 'Notion', 'Trello', 'Monday', 'I do not need any integration']
  const currentIsOther = integration && !integrations.includes(integration)
  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <div className="text-lg	bold mb-3">[2/3] Which integration would like to see with Hillia?</div>
      {integrations.map((current) => (
        <Radio
          key={current}
          checked={integration === current}
          label={current}
          name="integration"
          onChange={() => {
            setIntegration(current)
          }}
        />
      ))}
      <Radio checked={currentIsOther} label="Other" name="integration" onChange={setIntegration} />
      {currentIsOther ? (
        <Input
          required
          placeholder="Name of the platform"
          onChange={(event) => {
            setIntegration(event.target.value)
          }}
        />
      ) : null}
      <Button variant="primary" onClick={onSubmit} className="mt-3">
        Next
      </Button>
    </form>
  )
}

const Donation = ({ onClose }) => (
  <>
    <div className="text-base">
      I really appreciate your feedback, do not hesitate to submit more <strong>feature requests</strong> or{' '}
      <strong>bug reports</strong>.
    </div>

    <div className="flex">
      <Button
        className="mt-3 mr-3"
        icon={BeerIcon}
        variant="primary"
        onClick={(event) => {
          event.preventDefault()
          window.open('ƒhttps://www.paypal.com/donate/?hosted_button_id=HLKQVEVFR3WNC')
        }}
      >
        Buy me a beer
      </Button>
      <Button
        className="mt-3"
        variant="secondary"
        onClick={(event) => {
          event.preventDefault()
          onClose()
        }}
      >
        No, do not ask again!
      </Button>
    </div>
  </>
)

const FreeForm = ({ onSubmit }) => {
  const [value, setValue] = useState('')
  return (
    <>
      <div className="text-lg	bold mb-3">[3/3] 3. Do you have any other comments, questions, or concerns?</div>
      <Input
        multiline
        placeholder="Feel free to provide any feedback"
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
        }}
      />
      <Button
        variant="primary"
        onClick={(event) => {
          event.preventDefault()
          onSubmit(value)
        }}
        className="mt-3"
      >
        Submit
      </Button>
    </>
  )
}

const NPSForm = () => {
  const { account } = useAccount()
  const oldAccount = dayjs().diff(dayjs(account.created_at * 1000), 'day') > 7

  const [show, setShow] = useState(oldAccount && !get('hide-nps-form'))

  useEffect(() => {
    if (show) {
      ahoy.track('nps-form-displayed', true)
    }
  }, [show])
  const [step, setStep] = useState(0)
  const [integration, setIntegration] = useState(0)
  const [nps, setNps] = useState()
  const saveNps = (value) => (event) => {
    event.preventDefault()
    setNps(value)
    setStep((step) => step + 1)
    ahoy.track('nps-value', value)
    set('hide-nps-form', true)
  }

  const submitIntegration = (event) => {
    event.preventDefault()
    setStep((step) => step + 1)
    ahoy.track('nps-integration', { nps, integration })
    set('hide-nps-form', true)
  }

  const saveFreeForm = (value) => {
    set('hide-nps-form', true)
    ahoy.track('nps-free-form', { nps, value })
    if (nps > 8) {
      setStep((step) => step + 1)
    } else {
      setShow(false)
    }
  }

  const saveDonation = () => {
    set('hide-nps-form', true)
    setShow(false)
  }

  return (
    <Dialog
      show={show}
      title={step === 3 ? 'Thank you for using Hillia! 🤩' : '🎭 Quick survey to improve Hillia'}
      onHide={() => {
        setShow(false)
        set('hide-nps-form', true)
      }}
    >
      <div className="p-6">
        {step === 0 ? (
          <NPSQuestion onSubmit={saveNps} />
        ) : step === 1 ? (
          <IntegrationQuestion integration={integration} setIntegration={setIntegration} onSubmit={submitIntegration} />
        ) : step === 2 ? (
          <FreeForm onSubmit={saveFreeForm} />
        ) : step === 3 ? (
          <Donation onSubmit={saveDonation} onClose={saveDonation} />
        ) : null}
      </div>
    </Dialog>
  )
}

export default NPSForm
