import React from 'react'
import NewTask, { COLORS } from '@src/pages/projects/NewTask'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import SavingContext from '@src/hooks/SavingContext'
import Grip from '@images/grip.svg'
import { useFetch } from 'react-async'
import sortBy from 'lodash/sortBy'
import TaskListItem from '@src/pages/projects/TaskListItem'
import Button from '@src/components/Button'
import Trash from '@images/trash.svg'
import throttle from 'lodash/throttle'
import Popover, { PopoverContext } from '@src/components/Popover'
import Label from '@src/components/Label'
import './Section.css'

const Color = ({ color, onChange }) => {
  const setShow = React.useContext(PopoverContext)

  return (
    <button
      key={color.id}
      type="button"
      title={color}
      onClick={() => {
        setShow(false)
        onChange(color)
      }}
      className="rounded-full h-4 w-4 m-2 opacity-75 hover:opacity-100 cursor-pointer"
      style={{ backgroundColor: color }}
    />
  )
}

const SectionHeader = ({ section, onChange, onRemove, draggable }) => {
  const [, setSaved] = React.useContext(SavingContext)

  const destroy = async () => {
    await fetch(`/projects/${section.project_id}/sections/${section.id}`, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'delete'
    })

    setSaved(true)
  }

  const update = React.useCallback(
    throttle((attributes) => {
      fetch(`/projects/${section.project_id}/sections/${section.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ section: attributes })
      })
      setSaved(true)
    }, 1000),
    [section?.id]
  )

  return (
    <div className="flex py-1 px-3 text-lg items-center section-title">
      <div {...draggable.dragHandleProps} className="section-title-handle">
        <Grip />
      </div>
      <Popover
        position="right"
        button={({ onClick }) => (
          <button
            type="button"
            style={{ backgroundColor: section.color }}
            className="cursor-pointer rounded-full h-3 w-3 mx-2"
            title="Change color"
            onClick={onClick}
          />
        )}
      >
        <div className="px-2 pt-2">
          <Label>Color</Label>
        </div>
        <div className="flex flex-wrap">
          {COLORS.map((color) => (
            <Color
              key={color}
              color={color}
              onChange={(color) => {
                onChange({ ...section, color })
                update({ color })
              }}
            />
          ))}
        </div>
        <div className="p-2">
          <Label>Size</Label>
          <input
            type="range"
            name="radius"
            min="8"
            max="16"
            className="w-full"
            value={section.radius}
            onChange={(event) => {
              const radius = Number(event.target.value)
              onChange({ ...section, radius })
              update({ radius })
            }}
          />
        </div>
      </Popover>
      <input
        className="w-full py-2 outline-none font-semibold flex-grow section-name-input"
        placeholder="Name of the scope"
        style={{ minWidth: 200 }}
        value={section?.name || ' '}
        onChange={(event) => {
          onChange({ ...section, name: event.target.value })
          update({ name: event.target.value })
          if (event.target.value === '' && section.name === '') {
            setSaved(true)
            onRemove(section)
            destroy()
          }
        }}
      />
      <Button
        variant="quick-action"
        className="mr-1"
        title="Delete scope"
        onClick={() => {
          if (window.confirm('You are going to delete this scope and all its tasks are you sure ?')) {
            setSaved(true)
            onRemove(section)
            destroy()
          }
        }}
      >
        <Trash />
      </Button>
    </div>
  )
}

const Section = React.forwardRef(
  (
    {
      autoFocus,
      section,
      tasks,
      members,
      projectId,
      setTasks,
      focusState,
      onRemove,
      hideCompleted,
      onChangeSection,
      onSelect,
      draggable,
      readOnly,
      assignee
    },
    ref
  ) => {
    const fallbackRef = React.useRef()
    const newTaskRef = ref || fallbackRef
    const inputRefs = React.useRef({})
    const [focus, setFocus] = focusState

    const onChange = React.useCallback((updatedTask) => {
      setTasks((tasks) => tasks.map((task) => (task.id === updatedTask.id ? updatedTask : task)))
    }, [])

    const onSubmit = React.useCallback((newTask) => {
      setTasks((tasks) => [...tasks, newTask])
    }, [])

    const onDelete = (deletedTask) => {
      const previousTask = tasks[tasks.findIndex((task) => task.id === deletedTask.id) - 1]
      if (previousTask) {
        setFocus(previousTask)
      }
      setTasks((tasks) => tasks.filter((task) => task.id !== deletedTask.id))
    }

    const onEnter = (pressedTask) => {
      const nextTask = tasks[tasks.findIndex((task) => task.id === pressedTask.id) + 1]
      if (nextTask) {
        setFocus(nextTask)
      } else {
        newTaskRef.current.focus()
      }
    }

    return (
      <div key={section?.id} ref={draggable.innerRef} {...draggable.draggableProps}>
        {section?.name !== undefined ? (
          <SectionHeader section={section} onChange={onChangeSection} onRemove={onRemove} draggable={draggable} />
        ) : null}

        <Droppable droppableId={String(section?.id || 0)} type="tasks">
          {(droppable) => (
            <div ref={droppable.innerRef} style={{ minHeight: 20 }}>
              {sortBy(tasks, 'position').map((task, index) => {
                if (!task.isNew) {
                  if (hideCompleted && task.completed) {
                    return null
                  }
                  if (assignee && task.account?.id !== assignee) {
                    return null
                  }
                }

                return (
                  <TaskListItem
                    key={task.id}
                    ref={inputRefs[index]}
                    index={index}
                    task={task}
                    onChange={onChange}
                    onDelete={onDelete}
                    onEnter={onEnter}
                    members={members}
                    focused={focus?.id === task.id}
                    onSelect={onSelect}
                    readOnly={readOnly}
                  />
                )
              })}
              {droppable.placeholder}
              {!readOnly && (
                <NewTask
                  ref={newTaskRef}
                  autoFocus={autoFocus}
                  projectId={projectId}
                  sectionId={section ? section.id : null}
                  position={tasks.length}
                  onSubmit={onSubmit}
                />
              )}
            </div>
          )}
        </Droppable>
      </div>
    )
  }
)

const DraggableSection = ({ index, ...props }) => (
  <Draggable draggableId={`section-${props.section.id}`} index={index} type="section">
    {(draggable) => <Section draggable={draggable} {...props} />}
  </Draggable>
)

export default DraggableSection
