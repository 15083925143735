import React, { useState } from 'react'
import Dialog from '@src/components/Dialog'
import Alert from '@src/components/Alert'
import Button from '@src/components/Button'
import Google from '@images/google.svg'
import Github from '@images/github.svg'
import Logo from '@images/white_logo.png'
import ApiImage from '@images/home_api.svg'
import RemoteImage from '@images/remote.svg'
import ChartImage from '@images/chart.svg'
import { useNavigate, useMatch } from 'react-router'
import classNames from 'classnames'
import Cookies from 'js-cookie'
import SlackIcon from '@images/slack-icon.png'
import AsanaIcon from '@images/asana.png'
import JiraIcon from '@images/jira.png'
import ZapierIcon from '@images/zapier.png'

import ContactForm from './ContactForm'
import DynamicHillchart from './DynamicChart'
import Tos from './Tos'
import Privacy from './Privacy'
import './Homepage.css'

const ValueProposal = ({ title, children, className, icon: Icon }) => (
  <div className={classNames('md:flex-1 md:mt-0 mt-16 px-6', className)}>
    <div className="flex justify-center mb-8">
      <Icon style={{ height: 150 }} className="block" />
    </div>
    <h3 className="montserrat font-bold text-xl mb-3">{title}</h3>
    <div className="text-base" style={{ lineHeight: '28px' }}>
      {children}
    </div>
  </div>
)

const Homepage = () => {
  const navigate = useNavigate()
  const showContact = useMatch('/contact')
  const showTos = useMatch('/terms')
  const showPrivacy = useMatch('/privacy')
  const [showInvitation, setShowInvitation] = useState(Cookies.get('invitation_token'))

  const hideContact = () => {
    navigate('/')
  }

  const hideTos = () => {
    navigate('/')
  }

  const hidePrivacy = () => {
    navigate('/')
  }

  const rejectInvite = () => {
    Cookies.remove('invitation_token')
    setShowInvitation(false)
  }

  return (
    <div className="homepage">
      {showInvitation ? (
        <Alert variant="main" onDismiss={rejectInvite}>
          <div className="container m-auto">
            <div className="text-lg">You have been invited to join Hillia</div>
          </div>
        </Alert>
      ) : null}
      <div className="flex flex-col">
        <div className="container m-auto" style={{ minHeight: '70vh' }}>
          <div className="text-lg mt-4 flex justify-between px-8 md:px-0">
            <div>
              <img width="100" src={Logo} title="Hillia" alt="Hillia logo" />
            </div>
          </div>
          <h1 className="text-3xl md:text-5xl montserrat text-center mt-16 px-6 md:px-0">
            Communicate Progress with Hillcharts
          </h1>
          <h2 className="text-xl mt-6 text-center px-8">
            Hillia is a free tool to visualize where your projects stand
          </h2>
          <div className="flex items-center justify-center">
            <div className="flex justify-center py-12 flex-col-reverse md:flex-row px-8 md:w-2/3 md:space-x-6">
              <div className="space-y-3 flex flex-col items-center justify-center">
                <Button
                  variant="rounded"
                  className="self-center"
                  onClick={(event) => {
                    event.preventDefault()
                    window.location.pathname = '/accounts/auth/google_oauth2'
                  }}
                >
                  <Google />
                  <span>Start with your Google account</span>
                </Button>
                <Button
                  variant="rounded"
                  className="self-center"
                  onClick={(event) => {
                    event.preventDefault()
                    window.location.pathname = '/accounts/auth/github'
                  }}
                >
                  <Github />
                  <span>Start with your Github account</span>
                </Button>
              </div>
              <div className="mb-12 md:mb-0 -ml-5 md:ml-0">
                <DynamicHillchart />
              </div>
            </div>
          </div>
        </div>
        <div className="homepage-white">
          <div className="container m-auto">
            <div className="md:flex text-center py-0 md:my-24">
              <ValueProposal icon={ChartImage} title="Get an overhead view of your project">
                Project progress is not linear, Hillcharts provide a clear distinction between the exploration and
                execution phases, enabling you to visualize the entire project landscape at a glance.
              </ValueProposal>
              <ValueProposal icon={ApiImage} title="Connect with all your tools">
                Effortlessly share Hillchart updates in real-time on <strong>Slack</strong>. <br />
                Sync your Hillia projects with your <strong>Jira</strong> boards, <strong>Trello</strong> boards,{' '}
                <strong>Asana</strong> projects, or <strong>Zapier</strong> using{' '}
                <a href="https://app.swaggerhub.com/apis-docs/Hil5/Hillia/1.3" className="underline" target="_blank">
                  Hillia's API
                </a>
                .
                <div className="flex justify-center items-center mt-4 space-x-2">
                  <img title="Slack" src={SlackIcon} className="w-8" />
                  <img title="Jira" src={JiraIcon} className="w-8" />
                  <img title="Zapier" src={ZapierIcon} className="w-8" />
                  <img title="Asana" src={AsanaIcon} className="w-8" />
                </div>
              </ValueProposal>
              <ValueProposal icon={RemoteImage} title="Follow progress asynchronously and remotely">
                Every change made on the Hill charts is instantly reflected in the project timeline, offering you a
                straightforward way to track progress and receive updates over time
              </ValueProposal>
            </div>

            <div className="flex justify-center my-24">
              <div className="md:w-2/3 self-center">
                <h2 className="text-xl montserrat text-center mb-10 font-bold px-6 md:px-0">
                  Hillcharts, a concept introduced by Basecamp
                </h2>
                <div className="flex items-start flex-col-reverse md:flex-row">
                  <div className="text-lg px-6 md:px-0">
                    <div>
                      Hillcharts were introduced in 2018 in the{' '}
                      <a href="https://basecamp.com/" target="_blank" className="underline" rel="noreferrer">
                        Basecamp
                      </a>{' '}
                      project management tool.
                    </div>
                    <br />
                    <div>
                      If you can't use Basecamp but you still want to follow the{' '}
                      <a href="https://basecamp.com/shapeup" target="_blank" className="underline" rel="noreferrer">
                        Shape Up
                      </a>{' '}
                      philosophy, you can use Hillia for free to track progress of your own projects with Hillcharts.
                    </div>
                    <br />
                    <div>
                      <a href="https://basecamp.com/" target="_blank" className="underline" rel="noreferrer">
                        Basecamp
                      </a>
                      &nbsp;is a complete project management toolkit that provides among others to-do lists, milestone
                      management, forum-like messaging, file sharing, time tracking tools and Hillcharts which are
                      completely integrated.
                    </div>
                    <br />
                    <div className="flex flex-col md:flex-row space-x-3 h-10">
                      <Button
                        component="a"
                        href="https://basecamp.com/features/hill-charts"
                        target="_blank"
                        variant="orange"
                      >
                        What are Hillcharts ?
                      </Button>
                      <Button component="a" href="https://basecamp.com/" target="_blank" variant="outline">
                        Know more about Basecamp
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-6">
        <Dialog show={showTos} onHide={hideTos} position="right" title="Terms of Service" padding>
          <Tos />
        </Dialog>
        <Dialog show={showPrivacy} onHide={hidePrivacy} position="right" title="Privacy Policy" padding>
          <Privacy />
        </Dialog>
        <Dialog show={showContact} onHide={hideContact} position="right" title="Contact" padding>
          <ContactForm onSuccess={hideContact} onHide={hideContact} />
        </Dialog>
        <a
          href="#"
          className="underline"
          onClick={(event) => {
            event.preventDefault()
            navigate('/contact')
          }}
        >
          Contact
        </a>
        {' ● '}
        <a
          href="#"
          className="underline"
          onClick={(event) => {
            event.preventDefault()
            navigate('/terms')
          }}
        >
          Terms of Service
        </a>
        {' ● '}
        <a
          href="#"
          className="underline"
          onClick={(event) => {
            event.preventDefault()
            navigate('/privacy')
          }}
        >
          Privacy
        </a>
      </div>
    </div>
  )
}

export default Homepage
