import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import './Button.css'

const Button = ({
  component: Component = 'button',
  variant,
  className,
  icon: IconComponent,
  block,
  children,
  type = 'button',
  ...rest
}) => {
  if (rest.to) {
    Component = Link
  }

  return (
    <Component
      className={classNames('hillia-button text-base', className, {
        [`hillia-button-${variant}`]: !!variant,
        'w-full': block
      })}
      type={type}
      {...rest}
    >
      {IconComponent && <IconComponent className="mr-2" />}
      {children}
    </Component>
  )
}

export default Button
