import React from 'react'
import { BrowserRouter, Routes, Route, Navigate, useRouteError } from 'react-router-dom'
import Homepage from '@src/pages/Homepage'
import ahoy from 'ahoy.js'
import Welcome, { TeamList } from '@src/pages/Welcome'
import Team from '@src/pages/teams/Team'
import { Layout, Main } from '@src/pages/projects/Layout'
import useAccount, { AccountProvider } from '@src/hooks/useAccount'
import Project from '@src/pages/projects/Project'
import Settings from '@src/pages/settings/Settings'
import PublicProject from '@src/pages/projects/PublicProject'
import AppSidebar from '@src/layout/AppSidebar'
import NPSForm from '@src/layout/NPSForm'
import NewFeature from '@src/layout/NewFeature'
import ProjectForm from '@src/pages/projects/ProjectForm'
import TeamForm from '@src/pages/teams/TeamForm'
import Menu from '@images/menu.svg'
import SlackConfig from '@src/pages/projects/SlackConfig'
import Timeline from '@src/pages/projects/Timeline'
import Hill from '@src/pages/projects/Hill'
import ApiTab from '@src/pages/projects/ApiTab'
import ProjectList from '@src/pages/projects/ProjectList'
import TeamApi from '@src/pages/teams/TeamApi'
import TeamMembers from '@src/pages/teams/TeamMembers'
import DashboardList from '@src/pages/dashboards/DashboardList'
import Dashboard from '@src/pages/dashboards/Dashboard'

import './App.css'

ahoy.configure({
  visitsUrl: '/hillia/v',
  eventsUrl: '/hillia/e'
})

export const FormsContext = React.createContext()

const AllRoutes = () => {
  const { account } = useAccount()
  const [docked, setDocked] = React.useState(true)
  const [drawer, setDrawer] = React.useState()

  if (!account) {
    return <Homepage />
  }

  return (
    <FormsContext.Provider value={{ setDrawer, drawer }}>
      <Layout>
        <Main>
          <div className="sidebar-toggle">
            <button
              title="Open menu"
              type="button"
              onClick={() => {
                setDocked(false)
              }}
            >
              <Menu />
            </button>
          </div>
          <TeamForm show={drawer === 'team'} onHide={() => setDrawer(null)} />
          <ProjectForm show={drawer === 'project'} onHide={() => setDrawer(null)} />
          <NPSForm />
          <NewFeature />
          <Routes>
            <Route path="/" element={<Welcome />}>
              <Route index element={<ProjectList />} />
              <Route path="teams" element={<TeamList />} />
              <Route path="dashboards" element={<DashboardList />} />
            </Route>

            <Route path="/teams/:id" element={<Team />}>
              <Route index element={<ProjectList />} />
              <Route path="members" element={<TeamMembers />} />
              <Route path="api" element={<TeamApi />} />
              <Route path="dashboards" element={<DashboardList />} />
            </Route>

            <Route path="projects/:id" element={<Project />}>
              <Route index element={<Hill />} />
              <Route path="slack" element={<SlackConfig />} />
              <Route path="timeline" element={<Timeline />} />
              <Route path="api" element={<ApiTab />} />
            </Route>

            <Route path="dashboards/:dashboard_id" element={<Dashboard />} />
            <Route path="settings" element={<Settings />} />
          </Routes>
        </Main>
        <AppSidebar account={account} docked={docked} onClose={() => setDocked(true)} />
      </Layout>
    </FormsContext.Provider>
  )
}

const ErrorPage = () => {
  const error = useRouteError()
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  )
}

const App = () => (
  <AccountProvider>
    <BrowserRouter errorElement={<ErrorPage />}>
      <Routes>
        <Route exact path="/p/:token" element={<PublicProject />} />
        <Route path="*" element={<AllRoutes />} />
      </Routes>
    </BrowserRouter>
  </AccountProvider>
)

export default App
