import { useFetch } from 'react-async'
import useAccount from '@src/hooks/useAccount'

const useTeamDeletion = (team) => {
  const { setAccount } = useAccount()
  const { run: deleteTeam } = useFetch(
    team.url,
    { method: 'delete' },
    {
      defer: true,
      onResolve: () => {
        window.location.reload()
        setAccount((account) => ({
          ...account,
          teams: account.teams.filter(({ id }) => id !== team.id)
        }))
      },
      onReject: console.error
    }
  )
  return () => {
    if (window.confirm(`Delete team "${team.name}" and all its projects ?`)) {
      deleteTeam()
    }
  }
}
export default useTeamDeletion
