import React, { useState } from 'react'
import FontFaceObserver from 'fontfaceobserver'

const useFontFamily = (fontName) => {
  const [fontFamily, setFontFamily] = useState("'Roboto', sans-serif")

  React.useEffect(() => {
    const font = new FontFaceObserver(fontName)
    font.load().then(() => {
      setFontFamily(`'${fontName}', sans-serif`)
    })
  }, [])

  return fontFamily
}

export default useFontFamily
