import React from 'react'

const usePrevousValue = (touched, tasks) => {
  const oldTasks = React.useRef()

  React.useEffect(() => {
    if (!touched || !oldTasks.current) {
      oldTasks.current = tasks
    }
  }, [touched, tasks])

  return oldTasks.current
}

export default usePrevousValue
