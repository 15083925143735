import React from 'react'
import Dialog from '@src/components/Dialog'
import Row from '@src/components/Row'
import Button from '@src/components/Button'
import useAccount from '@src/hooks/useAccount'
import ButtonHint from '@src/layout/ButtonHint'
import QuestionIcon from '@images/question.svg'

const UserVoiceForm = ({ onSuccess, onHide }) => {
  const { account } = useAccount()
  const [message, setMessage] = React.useState('')
  const submit = (event) => {
    event.preventDefault()
    const form = event.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        onSuccess()
      }
    }
    xhr.send(data)
    alert('Got it. Thank you !')
  }

  return (
    <form onSubmit={submit} action="https://formspree.io/mzbjvzgy" method="POST" className="mt-3">
      <input type="hidden" name="account_id" value={account.id} />
      <input type="hidden" name="account_email" value={account.email} />
      <Row
        label="Your message"
        placeholder="Missing feature? A bug to report?"
        name="message"
        required
        multiline
        value={message}
        onChange={(event) => setMessage(event.target.value)}
      />

      <div className="flex justify-end">
        <Button
          onClick={(event) => {
            event.preventDefault()
            onHide()
          }}
          className="mr-3"
          variant="secondary"
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={!message}>
          Send
        </Button>
      </div>
    </form>
  )
}

const UserVoiceLink = () => {
  const [showContact, setShowContact] = React.useState(false)

  const hideContact = () => {
    setShowContact(false)
  }

  return (
    <div>
      <Dialog show={showContact} onHide={hideContact} position="right" title="Report a bug / request a feature" padding>
        <UserVoiceForm onSuccess={hideContact} onHide={hideContact} />
      </Dialog>

      <Button
        title="Bug | Feature request"
        variant="sidebar"
        onClick={() => {
          setShowContact(true)
        }}
      >
        <QuestionIcon width={28} />
        <ButtonHint>Report a bug / request a feature</ButtonHint>
      </Button>
    </div>
  )
}

export default UserVoiceLink
