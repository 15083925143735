import React, { useState, useMemo } from 'react'
import Fuse from 'fuse.js'
import Button from '@src/components/Button'
import Row from '@src/components/Row'
import { useOutletContext } from 'react-router-dom'
import useAccount from '@src/hooks/useAccount'
import DeleteIcon from '@images/trash.svg'
import { useFetch } from 'react-async'
import { List, ListItem } from '@src/components/List'
import Edit from '@images/edit.svg'
import { CardContent, CardFooter } from '@src/components/Card'
import TeamPlanRequired from '@src/pages/teams/TeamPlanRequired'
import NewMemberDialog from './NewMemberDialog'

const Invites = ({ team, invites, onDelete }) => {
  return (
    <List>
      {invites.map((invite) => (
        <ListItem key={invite.id} className="flex items-center text-base">
          <div className="flex items-center flex-grow">
            <div className="rounded-full h-10 w-10 mr-3 bg-gray-200" />
            <div className="">
              {invite.email} <span className="text-gray-500">(invitation pending)</span>
            </div>
          </div>
          <button
            type="button"
            title="Remove from team"
            className="ml-1"
            onClick={async () => {
              await fetch(`/teams/${team?.id}/invites/${invite.id}`, {
                method: 'delete'
              })
              onDelete(invite)
            }}
          >
            <DeleteIcon />
          </button>
        </ListItem>
      ))}
    </List>
  )
}
export default function TeamMembers() {
  const { team } = useOutletContext()
  const [show, setShow] = React.useState(false)
  const { account } = useAccount()
  const [search, setSearch] = useState('')

  const [members, invites] = useMemo(() => {
    if (!search) return [team.members, team.invites]
    return [
      new Fuse(team.members, { keys: ['name', 'email'], threshold: 0.4 }).search(search).map(({ item }) => item),
      new Fuse(team.invites, { keys: ['email'], threshold: 0.4 }).search(search).map(({ item }) => item)
    ]
  }, [search, team.members.length, team.invites.length])

  const onDeleteInvite = () => {
    window.location.reload()
  }

  const { run: removeMember } = useFetch(
    `/account_teams/${team?.id}`,
    { method: 'delete', headers: { 'Content-Type': 'application/json' } },
    {
      defer: true,
      onReject: console.error,
      onResolve: () => {
        window.location.reload()
      }
    }
  )

  const { run: setOwner } = useFetch(
    `/teams/${team?.id}/owner`,
    { method: 'put', headers: { 'Content-Type': 'application/json' } },
    {
      defer: true,
      onReject: console.error,
      onResolve: async (response) => {
        const jsonBody = await response.json()
        if (jsonBody.success) {
          window.location.reload()
          return
        }

        if (jsonBody.reason === 'inactive_subscription') {
          alert(
            'Because the current owner of the team has an active subscription, the new owner must also have an active subscription.'
          )
        }
      }
    }
  )

  return (
    <>
      <CardContent>
        {team.members.length > 10 ? (
          <Row
            label="Search a member"
            className="my-b"
            type="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Name or email of a team member"
          />
        ) : null}
        {search ? <div className="font-bold mb-3">{members.length + invites.length} results:</div> : null}

        <List>
          {members.map((member) => (
            <ListItem key={member.id} className="flex items-center">
              <div className="flex-grow flex items-center justify-between">
                <div className="flex items-center">
                  <img src={member.image} title={member.name} className="rounded-full h-10 w-10 mr-3" />
                  <div>
                    <div className="items-center lg:flex xs:flex-col">
                      <div className="text-base">{member.name}</div>
                      {member.id === team.owner.id ? (
                        <span className="text-xs ml-1 p-1 text-white rounded bg-orange-400">TEAM OWNER</span>
                      ) : null}
                      {member.paid ? (
                        <span className="text-xs ml-1 p-1 text-white rounded bg-green-300">ENTERPRISE PLAN</span>
                      ) : null}
                    </div>
                    <div className="text-small">{member.email}</div>
                  </div>
                </div>
                <div className="flex items-center hidden lg:flex">
                  {account.id === team.owner.id && member.id !== team.owner.id ? (
                    <Button
                      type="button"
                      variant="outline"
                      className="mr-3"
                      icon={Edit}
                      onClick={() => {
                        let message = `Are you sure that you want to give all admin rights to ${member.name}? `
                        if (team.paid) {
                          message += 'The current owner of the team already has an effective Hillia Enterprise plan.'
                        }
                        if (window.confirm(message)) {
                          setOwner((init) => ({
                            ...init,
                            body: JSON.stringify({ owner_id: member.id })
                          }))
                        }
                      }}
                    >
                      Transfer ownership
                    </Button>
                  ) : null}
                  {account.id === team.owner.id ? (
                    <Button
                      type="button"
                      variant="outline"
                      title="Remove from team"
                      disabled={member.id === team.owner.id}
                      icon={DeleteIcon}
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure that you want to remove ${member.name} from the team "${team.name}"?`
                          )
                        ) {
                          removeMember((init) => ({
                            ...init,
                            body: JSON.stringify({ member_id: member.id })
                          }))
                        }
                      }}
                    >
                      <span className="hidden lg:inline-block">Remove</span>
                    </Button>
                  ) : null}
                </div>
              </div>
            </ListItem>
          ))}
        </List>
        <TeamPlanRequired team={team} className="mt-4 mb-4" />
        <Invites invites={invites} team={team} onDelete={onDeleteInvite} />
      </CardContent>
      <CardFooter>
        <Button
          variant="primary"
          onClick={() => {
            setShow(true)
          }}
        >
          Invite
        </Button>
      </CardFooter>
      <NewMemberDialog
        show={show}
        onHide={() => {
          setShow(false)
        }}
        team={team}
      />
    </>
  )
}
