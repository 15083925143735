import React from 'react'
import Cookies from 'js-cookie'

function isUsingBrave() {
  if (window.navigator.brave !== undefined && window.navigator.brave.isBrave.name === 'isBrave') {
    return true
  }
  return false
}

const useBraveWarning = () => {
  React.useEffect(() => {
    if (isUsingBrave()) {
      const cookieName = 'brave-warning-displayed'
      const isDismissed = Cookies.get(cookieName)
      if (!isDismissed)
        alert(
          "Hello, you are currently using Brave. There have been reports of it not working well with canvas elements that Hillia relies on. If you're having trouble grabbing a scope on the hill, please try using a different browser."
        )
      Cookies.set(cookieName, 'true')
    }
  }, [])
}

export default useBraveWarning
