// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
}

.cover-wrapper {
  background-color: #3ecf8e;
  width: 100%;
}

.content-wrapper {
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  background-color: var(--bg-color);
  padding-bottom: 200px;
}

@media (min-width: 1024px) {

.content-wrapper {
    padding-bottom: 0px;
    box-shadow: -2px 0px 4px 0 #80808024
}
  }
`, "",{"version":3,"sources":["webpack://./app/javascript/src/pages/projects/Layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,OAAO;EACP,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,OAAO;EACP,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,iCAAiC;EACjC,qBAAqB;AAMvB;;AAJE;;AAXF;IAYI,mBAAmB;IACnB;AAEJ;EADE","sourcesContent":[".wrapper {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  flex: 1;\n  flex-direction: row-reverse;\n}\n\n.cover-wrapper {\n  background-color: #3ecf8e;\n  width: 100%;\n}\n\n.content-wrapper {\n  height: 100%;\n  overflow-y: scroll;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  position: relative;\n  min-height: 100%;\n  background-color: var(--bg-color);\n  padding-bottom: 200px;\n\n  @media (min-width: 1024px) {\n    padding-bottom: 0px;\n    box-shadow: -2px 0px 4px 0 #80808024;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
