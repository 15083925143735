import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { CardContent } from '@src/components/Card'
import { RestApi, CallbackForm } from '../projects/ApiKey'

export default function TeamApi() {
  const { team } = useOutletContext()
  return (
    <CardContent>
      <RestApi apiKey={team.api_key} team={team} />
      <CallbackForm team={team} />
    </CardContent>
  )
}
