import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useFetch } from 'react-async'
import Hillchart from '@src/components/Hillchart'
import Button from '@src/components/Button'
import ProjectHeader from '@src/pages/projects/ProjectHeader'
import ProjectUpdated from '@src/pages/projects/ProjectUpdated'
import { formatScopes } from '@src/model/task'
import useQuery from '@src/hooks/useQuery'
import StatusUpdate from '@src/pages/projects/StatusUpdate'
import Fullscreen from '@images/fullscreen.svg'
import useProject, { ProjectProvider } from '@src/hooks/useProject'

const Project = () => {
  const { project, setWidth, width } = useProject()
  const sections = formatScopes(project.sections, width)
  const lastSnapshot = project.status
  const [query] = useQuery()
  const chartOnly = query.get('chartOnly')

  React.useEffect(() => {
    setWidth(window.innerWidth > 1000 ? 800 : window.innerWidth * 0.9)
  }, [])

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (document?.documentElement?.requestFullscreen) {
        document.documentElement.requestFullscreen()
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
    }
  }

  if (!width) {
    return null
  }

  const hill = (
    <div>
      <div className="flex justify-center">
        <div style={{ width }} className="flex justify-end">
          <Button
            variant="tertiary"
            className="rounded-full m-3"
            style={{ height: 32, width: 32, padding: 0 }}
            onClick={toggleFullScreen}
            title="Toggle fullscreen"
          >
            <Fullscreen />
          </Button>
        </div>
      </div>

      <div className="flex justify-center">
        <Hillchart width={width} tasks={sections} />
      </div>
    </div>
  )

  if (chartOnly) {
    return hill
  }

  return (
    <div>
      <div className="pb-16 w-full">
        <div className="mx-auto" style={{ width }}>
          <div className="flex flex-col md:flex-row md:space-x-6 space-y-3 md:space-y-0 p-6">
            <div className="flex-1">
              <ProjectHeader readOnly project={project} />
            </div>
            {lastSnapshot ? (
              <div className="flex-1">
                <StatusUpdate snapshot={lastSnapshot} readOnly />
              </div>
            ) : null}
          </div>
        </div>
        <hr />
        {hill}
        <div className="mt-3">
          <ProjectUpdated project={project} />
        </div>
        <div className="mt-20 text-center">
          Project progress by{' '}
          <Link to="/" className="underline color-dark-orange">
            Hillia
          </Link>
        </div>
      </div>
    </div>
  )
}

const ProjectWithData = () => {
  const uuid = useParams().token
  return (
    <ProjectProvider uuid={uuid}>
      <Project />
    </ProjectProvider>
  )
}

export default ProjectWithData
