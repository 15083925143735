import { init } from '@sentry/browser'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from '@src/App'
import ahoy from 'ahoy.js'

if (window.environement === 'production') {
  init({
    dsn: 'https://cef12b40d5074e2da92ddba2bc6028f6@o415617.ingest.sentry.io/5306948'
  })
} else {
  ahoy.debug()
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('app-root')
  if (container.innerHTML.trim() === '') {
    const root = createRoot(container)
    root.render(<App tab="home" />)
  }
})
