import React, { useCallback } from 'react'
import { useFetch } from 'react-async'
import throttle from 'lodash/throttle'
import EditableRichText from '@src/components/EditableRichText'

const ProjectDescription = ({ projectId, defaultDescription }) => {
  const { run } = useFetch(
    `/projects/${projectId}`,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'put'
    },
    { defer: true, onReject: console.error }
  )

  const onChange = useCallback(
    throttle((value) => {
      if (value) {
        run((init) => ({
          ...init,
          body: JSON.stringify({ project: { description: value } })
        }))
      }
    }, 1000),
    [run]
  )

  return (
    <EditableRichText
      className="mt-3"
      placeholder="Describe your project in a few words"
      value={defaultDescription || ''}
      onChange={onChange}
    />
  )
}
export default React.memo(ProjectDescription)
