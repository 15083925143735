import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

const ProjectUpdated = ({ project, updatedAt }) => (
  <div className="text-center opacity-75 text-sm mb-16 md:mb-0">
    Last update:{' '}
    <Link className="underline color-green" to={`/projects/${project.id}/timeline`}>
      {dayjs(updatedAt).format('dddd[, ] MMMM DD [at] h[:]mm A')}
    </Link>
  </div>
)

export default ProjectUpdated
