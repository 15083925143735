import { useNavigate } from 'react-router-dom'
import { useFetch } from 'react-async'
import useAccount from '@src/hooks/useAccount'

const useProjectDeletion = (project, options = {}) => {
  const { archive = false, restore = false } = options
  const { setAccount } = useAccount()
  const navigate = useNavigate()

  const deleteProject = async () => {
    await fetch(`${project.url}?archive=${archive}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    })

    if (archive) {
      window.location.reload()
    } else {
      setAccount((account) => ({
        ...account,
        projects: account.projects.filter(({ id }) => id !== project.id)
      }))
    }
  }

  const { run: restoreProject } = useFetch(
    `/projects/${project.id}/restore.json`,
    { method: 'put' },
    {
      defer: true,
      onReject: console.error,
      onResolve: () => {
        window.location.reload()
      }
    }
  )
  const message = archive
    ? `Archive project "${project.name}"? You will still be able to access it through your team's page.`
    : `Delete project "${project.name}"? It will not be recoverable.`

  return () => {
    if (restore) {
      restoreProject()
    } else if (window.confirm(message)) {
      navigate('/')
      deleteProject()
    }
  }
}
export default useProjectDeletion
