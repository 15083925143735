// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover {
  position: relative;
  height: 100%;
  flex-shrink: 0;
}

.popover-content {
  top: calc(100% + 4px);
  right: 0;
  position: absolute;
  min-width: 200px;
  box-shadow:
    0 0 0 1px rgba(239, 239, 240, 0.3),
    0 5px 20px 0 rgba(21, 27, 38, 0.08);
  border-radius: 5px;
  z-index: 10;
  background: white;
  overflow: hidden;
}

.popover-right .popover-content {
  transform: translateX(90%);
}

.popover-item {
  height: 40px;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  width: 100%;
  color: var(--font-color);
}

.popover-item svg {
    margin-right: 8px;
  }

.popover-item:hover {
    border-radius: 5px 5px 0 0;
    background-color: var(--cover-color);
  }
`, "",{"version":3,"sources":["webpack://./app/javascript/src/components/Popover.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,QAAQ;EACR,kBAAkB;EAClB,gBAAgB;EAChB;;uCAEqC;EACrC,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,WAAW;EACX,wBAAwB;AAU1B;;AARE;IACE,iBAAiB;EACnB;;AAEA;IACE,0BAA0B;IAC1B,oCAAoC;EACtC","sourcesContent":[".popover {\n  position: relative;\n  height: 100%;\n  flex-shrink: 0;\n}\n\n.popover-content {\n  top: calc(100% + 4px);\n  right: 0;\n  position: absolute;\n  min-width: 200px;\n  box-shadow:\n    0 0 0 1px rgba(239, 239, 240, 0.3),\n    0 5px 20px 0 rgba(21, 27, 38, 0.08);\n  border-radius: 5px;\n  z-index: 10;\n  background: white;\n  overflow: hidden;\n}\n\n.popover-right .popover-content {\n  transform: translateX(90%);\n}\n\n.popover-item {\n  height: 40px;\n  font-size: 13px;\n  display: flex;\n  align-items: center;\n  padding: 0 8px;\n  width: 100%;\n  color: var(--font-color);\n\n  svg {\n    margin-right: 8px;\n  }\n\n  &:hover {\n    border-radius: 5px 5px 0 0;\n    background-color: var(--cover-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
