import React from 'react'
import ahoy from 'ahoy.js'
import Button from '@src/components/Button'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import Hillchart, { progressToX } from '@src/components/Hillchart'
import StatusUpdate from '@src/pages/projects/StatusUpdate'
import useProject from '@src/hooks/useProject'
import { useMediaQuery } from 'react-responsive'
import { useTheme } from '@src/pages/projects/Layout'
import './Timeline.css'
import { useFetch } from 'react-async'

const Body = ({ snapshots }) => {
  const isMediumScreen = useMediaQuery({ query: '(min-width: 1024px)' })
  const [index, setIndex] = React.useState(0)
  const width = isMediumScreen ? 500 : 300
  const [theme] = useTheme()
  const previous = () => setIndex((index) => (index === snapshots.length - 1 ? index : index + 1))
  const next = () => setIndex((index) => (index === 0 ? 0 : index - 1))
  const snapshot = snapshots[index]
  const mostRecentDate = dayjs(snapshots[0].created_at)
  const oldestDate = dayjs(snapshots[snapshots.length - 1].created_at)
  const currentProgress = dayjs(snapshots[index].created_at).diff(mostRecentDate, 'days')
  const oldestProgress = mostRecentDate.diff(oldestDate, 'days')
  const progress = -Math.round((currentProgress / oldestProgress) * 100)

  return (
    <>
      <div className="relative flex justify-between md:justify-center items-center mb-6 space-x-3">
        <Button variant="outline" onClick={previous} disabled={index === snapshots.length - 1}>
          {isMediumScreen ? '< Previous' : '<'}
        </Button>
        <div className="text-lg text-center" style={{ minWidth: isMediumScreen ? 250 : null }}>
          {dayjs(snapshot.created_at).format(isMediumScreen ? 'dddd, MMMM D, YYYY' : 'MM/DD/YYYY')}
        </div>
        <Button variant="outline" onClick={next} disabled={index === 0}>
          {isMediumScreen ? 'Next >' : '>'}
        </Button>
      </div>
      <div className="timeline-indicator">
        <div className="timeline-cursor" style={{ right: `calc(${progress}% - 8px)` }} />
      </div>
      <div className="relative mb-3 flex justify-center">
        <div className="pb-6 pl-6 w-full flex justify-center flex-col lg:flex-row">
          <div className="flex justify-center">
            <Hillchart
              width={width}
              theme={theme}
              tasks={snapshot.tasks.map((task) => ({
                ...task,
                radius: task.radius || 10, // Need to migrate data
                x: progressToX(task.progress, width)
              }))}
            />
          </div>
          <div className="p-4 w-full">
            <StatusUpdate snapshot={snapshot} />
          </div>
        </div>
      </div>
    </>
  )
}

const Timeline = () => {
  const { id } = useParams()
  const { project } = useProject()
  const { data, isLoading } = useFetch(`/projects/${id}.json`, {
    headers: { accept: 'application/json' }
  })
  const snapshots = data?.snapshots || []

  React.useEffect(() => {
    ahoy.track('project-timeline-visit', { project_id: project.id })
  }, [])

  if (!snapshots.length && !isLoading) {
    return (
      <div>
        <div className="text-lg text-center mb-3">There are no entries in the timeline of this project.</div>
      </div>
    )
  }

  return (
    <>
      <div className="flex items-center mb-6 flex-col md:flex-row">
        <h3 className="text-2xl font-bold montserrat">Timeline</h3>
      </div>
      {snapshots.length ? <Body snapshots={snapshots} /> : <div style={{ height: 300 }} />}
    </>
  )
}

export default Timeline
