import React from 'react'
import classNames from 'classnames'
import Card from '@src/components/Card'
import useAccount from '@src/hooks/useAccount'
import ProjectList from '@src/pages/projects/ProjectList'
import { Tab } from '@src/pages/projects/Tabs'
import { Routes, Route, Link, useParams, Outlet } from 'react-router-dom'
import TeamApi from './TeamApi'
import TeamMembers from './TeamMembers'

const Team = () => {
  const { account } = useAccount()
  const teamId = Number(useParams().id)
  const team = account.teams.find((team) => team.id === teamId)

  if (!team) {
    window.location.href = '/404'
    return null
  }

  return (
    <div>
      <div>
        <h2 className="text-3xl w-full montserrat mb-6 flex items-center">
          {team.name}
          <Link
            className={classNames('inline rounded border p-1 text-base ml-2', {
              'bg-green-300 text-white': team.paid,
              'bg-gray-300': !team.paid
            })}
            to="/settings"
          >
            {team.paid ? 'Enterprise plan' : 'Free plan'}
          </Link>
        </h2>
      </div>

      <Card>
        <div className="tabs relative pt-3">
          <Tab to={`/teams/${team.id}/`}>Projects</Tab>
          <Tab to={`/teams/${team.id}/dashboards`}>✨ Dashboards</Tab>
          <Tab to={`/teams/${team.id}/members`}>Members</Tab>
          <Tab to={`/teams/${team.id}/api`}>API</Tab>
        </div>
        <div>
          <Outlet context={{ team, projects: team.projects }} />
        </div>
      </Card>
    </div>
  )
}
const TeamLayout = (props) => (
  <div className="p-6">
    <div className="m-auto max-w-4xl">
      <Team {...props} />
    </div>
  </div>
)

export default TeamLayout
