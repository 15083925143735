import React, { useState, useCallback } from 'react'
import { Label, Tag, Text, Group, Circle } from 'react-konva'
import { PADDING } from '@src/components/Hillchart'

const TEXT_PADDING = 6
const ZOOM_RATIO = 1.2

const TaskLabel = React.memo(({ maxWidth, radius, x, name, fontFamily, theme }) => {
  const ref = React.useRef()

  // Use state to have width at mounting time
  const [width, setWidth] = useState(100)
  React.useEffect(() => {
    setWidth(ref.current?.getTextWidth())
  }, [name])

  const textX = React.useMemo(() => {
    return maxWidth - x < width ? -width - radius - TEXT_PADDING : radius + TEXT_PADDING
  }, [x, maxWidth, radius, width])

  const textColor = theme === 'dark' ? '#e1e1e1' : '#435f71'
  return (
    <Label x={textX} y={-TEXT_PADDING}>
      <Tag fill={theme === 'dark' ? '#242425' : 'white'} lineJoin="round" />
      <Text ref={ref} text={name} fontFamily={fontFamily} fontSize={14} fill={textColor} />
    </Label>
  )
})

const Task = ({
  calculateY,
  x,
  offset,
  radius,
  color,
  overred,
  name,
  update,
  maxWidth,
  id,
  canvasRef,
  onMouseOver: _onMouseOver,
  onMouseOut: _onMouseOut
}) => {
  const y = calculateY(x + PADDING)
  const [selected, setSelected] = useState(false)
  const onMouseOver = useCallback(() => {
    if (update && canvasRef?.current?.content) {
      canvasRef.current.content.style.cursor = 'grab'
    }
    _onMouseOver(id)
  }, [id, update, canvasRef.current])

  const onMouseOut = useCallback(() => {
    if (update && canvasRef?.current?.content) {
      canvasRef.current.content.style.cursor = 'default'
    }
    _onMouseOut(id)
  }, [id, update, canvasRef.current])

  const onDragStart = useCallback(() => {
    setSelected(true)
    canvasRef.current.content.style.cursor = 'grabbing'
  }, [canvasRef.current])

  const onDragEnd = useCallback(() => {
    setSelected(false)
    canvasRef.current.content.style.cursor = 'default'
  }, [canvasRef.current])

  const dragBoundFunc = useCallback(
    (pos) => ({
      x: pos.x < 0 ? 0 : pos.x > maxWidth ? maxWidth : pos.x,
      y: pos.y
    }),
    [maxWidth]
  )

  const onDragMove = useCallback(
    (e) => {
      const x = e.target.attrs.x
      const y = calculateY(x + PADDING)
      e.target.attrs.y = y
      update(id, { x, y })
    },
    [calculateY]
  )

  const stroke = overred ? '#737c81' : 'white'

  return (
    <Group
      x={x}
      y={y}
      offsetX={-PADDING}
      offsetY={offset}
      listening={Boolean(update)}
      draggable={Boolean(update)}
      dragBoundFunc={dragBoundFunc}
      onDragMove={onDragMove}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <Circle radius={selected ? radius * ZOOM_RATIO : radius} fill={color} stroke={stroke} strokeWidth={2} />
      <TaskLabel x={x} maxWidth={maxWidth} radius={radius} name={name} />
    </Group>
  )
}

export default React.memo(Task)
