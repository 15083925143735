import React from 'react'
import { useNavigate } from 'react-router-dom'
import Dialog from '@src/components/Dialog'
import Row from '@src/components/Row'
import Input from '@src/components/Input'
import Button from '@src/components/Button'
import Label from '@src/components/Label'
import { useFetch } from 'react-async'
import uniq from 'lodash/uniq'
import useAccount from '@src/hooks/useAccount'
import DashboardFormProjects from './DashboardFormProjects'
import EnterprisePill from '@src/pages/projects/EnterprisePill'

const DashboardForm = ({ team: propTeam, show, dashboard, onHide }) => {
  const navigate = useNavigate()
  const { account } = useAccount()
  const teamsById = account.teams.reduce((acc, elem) => ({ ...acc, [elem.id]: elem.name }), {})
  const [name, setName] = React.useState(dashboard?.name || '')
  const [projectIds, setProjectIds] = React.useState(dashboard?.project_ids || [])
  const [teamId, setTeamId] = React.useState(propTeam?.id || '')
  const team = propTeam || account.teams.find((team) => team.id === teamId)
  const creation = !dashboard
  const url = team ? (dashboard ? `/teams/${team.id}/dashboards/${dashboard.id}` : `/teams/${team.id}/dashboards/`) : ''

  const { run } = useFetch(
    url,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: dashboard ? 'put' : 'post'
    },
    {
      defer: true,
      onResolve: (dashboard) => {
        window.location.href = dashboard.url
      },
      onReject: console.error
    }
  )

  const onSubmit = (event) => {
    event.preventDefault()
    onHide()
    run((init) => ({
      ...init,
      body: JSON.stringify({
        dashboard: { name, project_ids: projectIds, team_id: teamId }
      })
    }))
  }

  const addProject = () => {
    setProjectIds((projects) => uniq([...projects, email]))
  }

  const removeProject = (project) => {
    setProjectIds((projects) => projects.filter((email) => email !== project))
  }

  return (
    <Dialog
      show={show}
      onHide={onHide}
      title={
        <h2 className="flex items-center">
          <span className="pl-3 text-2xl">{creation ? 'Create a new dashboard' : 'Update dashboard'} </span>
        </h2>
      }
      position="right"
      padding
    >
      <form onSubmit={onSubmit}>
        <Row
          label="Name of your dashboard"
          name="name"
          autoComplete="off"
          autoFocus
          required
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        {dashboard ? null : (
          <Row
            label="Team"
            name="team"
            type="select"
            required
            placeholder="Select a team"
            options={teamsById}
            value={teamId}
            onChange={(event) => {
              setTeamId(Number(event.target.value))
              setProjectIds([])
            }}
          />
        )}

        <DashboardFormProjects team={team} projectIds={projectIds} onChange={setProjectIds} />

        <div className="flex justify-end items-center space-x-3">
          {team && !team.paid ? (
            <div>
              <EnterprisePill />
            </div>
          ) : null}
          <Button
            onClick={(event) => {
              event.preventDefault()
              onHide()
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={!name || !team || !team.paid}>
            {creation ? 'Create ' : 'Update '}
          </Button>
        </div>
      </form>
    </Dialog>
  )
}

export default DashboardForm
