import React from 'react'
import Button from '@src/components/Button'
import Row from '@src/components/Row'
import Dialog from '@src/components/Dialog'
import { useFetch } from 'react-async'
import useAccount from '@src/hooks/useAccount'
import TeamPlanRequired from '@src/pages/teams/TeamPlanRequired'
import { canInvite } from '@src/model/team'

const NewMemberDialog = ({ show, team, onHide }) => {
  const [email, setEmail] = React.useState('')
  const { setAccount } = useAccount()
  const { run } = useFetch(
    `/teams/${team.id}`,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'put',
      body: JSON.stringify({ team: { invites_attributes: [{ email }] } })
    },
    {
      defer: true,
      onResolve: (updatedTeam) => {
        setAccount((account) => ({
          ...account,
          teams: account.teams.map((team) => (team.id === updatedTeam.id ? updatedTeam : team))
        }))

        alert(`A new team member has been added, they can join anytime by clicking on the link sent to "${email}".`)
      },
      onReject: console.error
    }
  )
  const alllowInvite = team && canInvite(team)

  return (
    <Dialog
      padding
      position="right"
      show={show}
      title={`Add a member to ${team.name}`}
      onHide={() => {
        onHide()
      }}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault()
          run()
          setEmail('')
        }}
      >
        <Row
          autoFocus
          label="Email address"
          placeholder="An invitation will be sent to this email address"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          disabled={!alllowInvite}
        />
        <TeamPlanRequired
          className="-mt-4 mb-4"
          team={team}
          onSubscribeClick={() => {
            onHide()
          }}
        />
        <div className="flex justify-end">
          <Button
            onClick={(event) => {
              event.preventDefault()
              onHide()
            }}
            type="button"
            className="mr-3"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={!email || !alllowInvite}>
            Invite to the team
          </Button>
        </div>
      </form>
    </Dialog>
  )
}
export default NewMemberDialog
