import React from 'react'
import { useNavigate } from 'react-router'
import ahoy from 'ahoy.js'
import Popover, { PopoverItem } from '@src/components/Popover'
import useProjectDeletion from '@src/pages/projects/useProjectDeletion'
import Trash from '@images/trash.svg'
import Slack from '@images/slack.svg'
import Edit from '@images/edit.svg'
import Archive from '@images/archive.svg'
import AddUser from '@images/add-user.svg'
import History from '@images/history.svg'
import { getSlackUrl } from '@src/model/project'
import Api from '@images/api.svg'
import Share from '@images/share.svg'
import ProjectForm from './ProjectForm'

const ProjectOptions = ({ project, button, position, onChange }) => {
  const navigate = useNavigate()
  const [show, setShow] = React.useState(false)
  const deleteProject = useProjectDeletion(project)
  const archiveProject = useProjectDeletion(project, { archive: true })
  const restoreProject = useProjectDeletion(project, { restore: true })

  return (
    <>
      <ProjectForm show={show} onHide={() => setShow(false)} project={project} onChange={onChange} />
      <Popover button={button} position={position}>
        <PopoverItem
          icon={<History />}
          onClick={() => {
            navigate(`/projects/${project.id}/timeline`)
          }}
        >
          Open timeline
        </PopoverItem>
        <PopoverItem
          icon={<AddUser />}
          onClick={() => {
            navigate(`/teams/${project.team?.id || project.team_id}`)
          }}
        >
          Invite to collaborate
        </PopoverItem>
        <PopoverItem
          icon={<Share />}
          onClick={() => {
            window.open(`/p/${project.token}`)
          }}
        >
          Share public link
        </PopoverItem>
        <>
          <PopoverItem
            icon={<Api />}
            onClick={() => {
              navigate(`/projects/${project.id}/api`)
            }}
          >
            API & Webhooks
          </PopoverItem>
          {project.slack_enabled ? (
            <PopoverItem
              icon={<Slack />}
              onClick={() => {
                ahoy.track('project-options-set-slack-channel', {
                  project_id: project.id
                })
                navigate(`/projects/${project.id}/slack`)
              }}
            >
              Change Slack configuration
            </PopoverItem>
          ) : (
            <PopoverItem
              icon={<Slack />}
              onClick={() => {
                ahoy.track('project-options-init-slack', {
                  project_id: project.id
                })
                window.location = getSlackUrl(project)
              }}
            >
              Add to Slack
            </PopoverItem>
          )}
        </>
        <hr />
        <PopoverItem
          icon={<Edit />}
          onClick={() => {
            setShow(true)
          }}
        >
          Open project settings
        </PopoverItem>
        {project.archived ? (
          <PopoverItem icon={<Archive />} onClick={restoreProject}>
            Restore project
          </PopoverItem>
        ) : (
          <PopoverItem icon={<Archive />} onClick={archiveProject}>
            Archive project
          </PopoverItem>
        )}
        <PopoverItem icon={<Trash />} onClick={deleteProject}>
          Delete project
        </PopoverItem>
      </Popover>
    </>
  )
}

export default ProjectOptions
