// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border-radius: 5px;
  background: var(--card-bg-color);
  box-shadow:
    0 0 0 1px rgba(239, 239, 240, 0.05),
    0 3px 6px 1px rgba(21, 27, 38, 0.03);
}
`, "",{"version":3,"sources":["webpack://./app/javascript/src/components/Card.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gCAAgC;EAChC;;wCAEsC;AACxC","sourcesContent":[".card {\n  border-radius: 5px;\n  background: var(--card-bg-color);\n  box-shadow:\n    0 0 0 1px rgba(239, 239, 240, 0.05),\n    0 3px 6px 1px rgba(21, 27, 38, 0.03);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
