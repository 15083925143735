// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-list-item {
  border-color: var(--border-color);
  border-top-width: 1px;
  padding-right: 2rem;
}

  .task-list-item:last-child {
    border-bottom-width: 1px;
  }

  .task-list-item input {
    outline: none;
    background: transparent;
  }

  .task-list-item .task-list-item-grip {
    opacity: 0;
    fill: var(--font-color);
  }

  .task-list-item:hover {
    background-color: var(--task-hover-color);
  }

  .task-list-item:hover .hillia-button-quick-action {
      visibility: visible;
    }

  .task-list-item:hover .task-list-item-grip {
      opacity: 1;
    }

  .task-list-item.task-list-item-completed input {
    opacity: 0.6;
  }

.task-list-item-keyboard-help {
  display: none;
}

form input:focus + .task-list-item-keyboard-help {
  cursor: default;
  white-space: pre;
  margin: 8px 12px;
  background-color: var(--bg-color);
  padding: 0 8px;
  border-radius: 7px;
  opacity: 0.7;
  display: flex;
  color: var(--font-color);
}
`, "",{"version":3,"sources":["webpack://./app/javascript/src/pages/projects/TaskListItem.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,qBAAqB;EACrB,mBAAmB;AA+BrB;;EA7BE;IACE,wBAAwB;EAC1B;;EAEA;IACE,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,UAAU;IACV,uBAAuB;EACzB;;EAEA;IACE,yCAAyC;EAS3C;;EAPE;MACE,mBAAmB;IACrB;;EAEA;MACE,UAAU;IACZ;;EAGF;IACE,YAAY;EACd;;AAGF;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,iCAAiC;EACjC,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,wBAAwB;AAC1B","sourcesContent":[".task-list-item {\n  border-color: var(--border-color);\n  border-top-width: 1px;\n  padding-right: 2rem;\n\n  &:last-child {\n    border-bottom-width: 1px;\n  }\n\n  input {\n    outline: none;\n    background: transparent;\n  }\n\n  .task-list-item-grip {\n    opacity: 0;\n    fill: var(--font-color);\n  }\n\n  &:hover {\n    background-color: var(--task-hover-color);\n\n    .hillia-button-quick-action {\n      visibility: visible;\n    }\n\n    .task-list-item-grip {\n      opacity: 1;\n    }\n  }\n\n  &.task-list-item-completed input {\n    opacity: 0.6;\n  }\n}\n\n.task-list-item-keyboard-help {\n  display: none;\n}\n\nform input:focus + .task-list-item-keyboard-help {\n  cursor: default;\n  white-space: pre;\n  margin: 8px 12px;\n  background-color: var(--bg-color);\n  padding: 0 8px;\n  border-radius: 7px;\n  opacity: 0.7;\n  display: flex;\n  color: var(--font-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
