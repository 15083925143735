import React from 'react'
import EnterprisePill from '@src/pages/projects/EnterprisePill'
import { Body } from './ApiKey'
import { Navigate, useOutletContext } from 'react-router-dom'
import useAccount from '@src/hooks/useAccount'

export default function ApiTab() {
  const { account } = useAccount()
  const { project } = useOutletContext()

  if (!project) {
    return <Navigate to="/404" />
  }

  const team = account.teams.find((team) => team.id === project.team_id)

  return (
    <>
      <div className="flex items-center mb-6 flex-col md:flex-row">
        <h3 className="text-2xl font-bold montserrat">API & Webhooks</h3>
        <div className="ml-2">
          {team && !team.paid ? (
            <div>
              <EnterprisePill />
            </div>
          ) : null}
        </div>
      </div>
      <Body project={project} team={team} />
    </>
  )
}
