import React from 'react'
import { useNavigate } from 'react-router-dom'
import useAccount from '@src/hooks/useAccount'
import { useFetch } from 'react-async'
import Button from '@src/components/Button'
import LogoutIcon from '@images/logout.svg'
import ButtonHint from '@src/layout/ButtonHint'

const SignoutButton = () => {
  const { setAccount } = useAccount()
  const { run } = useFetch(
    '/accounts/sign_out',
    { method: 'delete' },
    {
      defer: true,
      onResolve: () => {
        setAccount(null)
        window.location.href = '/'
      },
      onReject: console.error
    }
  )
  return (
    <Button variant="sidebar" title="Log out" onClick={() => run()}>
      <LogoutIcon width={28} />
      <ButtonHint>Log out</ButtonHint>
    </Button>
  )
}

export default SignoutButton
