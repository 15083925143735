import React, { useEffect, useState } from 'react'
import ahoy from 'ahoy.js'
import { useNavigate } from 'react-router-dom'
import { get, set } from '@src/Storage'
import BeerIcon from '@images/beer.svg'
import FeatureScreenshot from '@images/feature-1.png'
import Dialog from '@src/components/Dialog'
import Input from '@src/components/Input'
import Button from '@src/components/Button'
import dayjs from 'dayjs'
import useAccount from '@src/hooks/useAccount'

const DISMISSED_KEY = 'feature1'

const NewFeature = () => {
  const navigate = useNavigate()

  const { account } = useAccount()
  const oldAccount = dayjs().diff(dayjs(account.created_at * 1000), 'day') > 30
  const isTest = window.environement === 'test'
  const [show, setShow] = useState(!isTest && oldAccount && !get(DISMISSED_KEY))

  const dismiss = (event) => {
    set(DISMISSED_KEY, true)
  }

  const onHide = () => {
    setShow(false)
    set(DISMISSED_KEY, true)
  }

  return (
    <Dialog
      show={show}
      title={
        <div className="max-w-4xl m-auto">
          ✨ Introducing <span className="font-bold">Dashboards</span>
        </div>
      }
      onHide={onHide}
    >
      <div className="p-6 text-base max-w-4xl space-y-6 m-auto md:px-0">
        <div>
          Introducing a new feature that allows you to effortlessly track the progress of{' '}
          <strong>multiple Hill charts simultaneously by creating customized dashboards</strong>.
        </div>

        <img src={FeatureScreenshot} className="h-52" />
        <div className="flex items-center justify-center md:justify-start">
          <Button
            variant="primary"
            onClick={(event) => {
              event.preventDefault()
              navigate('/dashboards')
              onHide()
            }}
          >
            Try now
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default NewFeature
