import React from 'react'
import Popover, { PopoverItem } from '@src/components/Popover'
import useTeamDeletion from '@src/pages/teams/useTeamDeletion'
import Edit from '@images/edit.svg'
import Trash from '@images/trash.svg'
import TeamForm from './TeamForm'

const TeamOptions = ({ team, button }) => {
  const [show, setShow] = React.useState(false)
  const deleteTeam = useTeamDeletion(team)
  return (
    <>
      <TeamForm show={show} onHide={() => setShow(false)} team={team} />
      <Popover button={button}>
        <PopoverItem
          icon={<Edit />}
          onClick={() => {
            setShow(true)
          }}
        >
          Rename team
        </PopoverItem>
        <hr />
        <PopoverItem icon={<Trash />} onClick={deleteTeam}>
          Delete team
        </PopoverItem>
      </Popover>
    </>
  )
}

export default TeamOptions
