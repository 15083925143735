import React from 'react'
import classNames from 'classnames'
import './List.css'
import { Link } from 'react-router-dom'

export const List = ({ children }) => <div>{children}</div>

export const ListItem = ({ children, className, options, to, ...props }) => (
  <div className="flex items-center justify-between hillia-list-item">
    {to ? (
      <Link to={to} {...props} className={classNames('cursor-pointer w-full p-3 text-base relative', className)}>
        {children}
      </Link>
    ) : (
      <div {...props} className={classNames('w-full p-3 text-base relative', className)}>
        {children}
      </div>
    )}
    {options}
  </div>
)
