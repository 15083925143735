// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@images/bg-home.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepage {
  background: #11998e;
  background: linear-gradient(to right, #20bec6, #49dab8, #62e7b1);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  color: white;
}

.homepage-transition {
  background: linear-gradient(0deg, white, transparent);
  height: 250px;
  padding-top: 290px;
}

.homepage-white {
  background-color: white;
  color: var(--font-color);
}

.dynamic-hillchart {
  margin: auto;
  transform: perspective(630px) rotateY(-15deg);
  box-shadow:
    0 0 0 1px rgba(239, 239, 240, 0.2),
    0 3px 6px 1px rgba(21, 27, 38, 0.2);
}
`, "",{"version":3,"sources":["webpack://./app/javascript/src/pages/Homepage.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EAEnB,gEAAgE;EAChE,yDAA4C;EAC5C,YAAY;AACd;;AAEA;EACE,qDAAqD;EACrD,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,6CAA6C;EAC7C;;uCAEqC;AACvC","sourcesContent":[".homepage {\n  background: #11998e;\n  background: -webkit-linear-gradient(to right, #20bec6, #49dab8, #62e7b1);\n  background: linear-gradient(to right, #20bec6, #49dab8, #62e7b1);\n  background-image: url('@images/bg-home.png');\n  color: white;\n}\n\n.homepage-transition {\n  background: linear-gradient(0deg, white, transparent);\n  height: 250px;\n  padding-top: 290px;\n}\n\n.homepage-white {\n  background-color: white;\n  color: var(--font-color);\n}\n\n.dynamic-hillchart {\n  margin: auto;\n  transform: perspective(630px) rotateY(-15deg);\n  box-shadow:\n    0 0 0 1px rgba(239, 239, 240, 0.2),\n    0 3px 6px 1px rgba(21, 27, 38, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
