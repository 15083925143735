import React, { useCallback, useState, useRef } from 'react'
import { useOutletContext } from 'react-router-dom'
import Hillchart, { PADDING } from '@src/components/Hillchart'
import ahoy from 'ahoy.js'
import Button from '@src/components/Button'
import StatusUpdate from '@src/pages/projects/StatusUpdate'
import HistoryEntry from '@src/pages/projects/HistoryEntry'
import ProjectUpdated from '@src/pages/projects/ProjectUpdated'
import usePrevousValue from '@src/hooks/usePrevousValue'
import useMessageBeforeUnload from '@src/hooks/useMessageBeforeUnload'
import useAccount from '@src/hooks/useAccount'
import useProject from '@src/hooks/useProject'
import { useTheme } from '@src/pages/projects/Layout'
import Share from '@images/share.svg'

const Hill = () => {
  const { setAccount } = useAccount()
  const { sections, setSections, width } = useOutletContext()
  const { project, touched, setTouched } = useProject()
  const hillchartRef = useRef()
  const [theme] = useTheme()
  const [snapshots, setSnapshots] = useState(project.snapshots)
  const [updatedAt, setUpdatedAt] = useState(project.updated_at)
  const oldState = usePrevousValue(touched, sections)
  const latestStatus = snapshots.find(({ comment, status }) => comment || status)

  const update = useCallback((transformState) => {
    setSections(transformState)
    setTouched(true)
  }, [])

  const cancel = useCallback(
    (event) => {
      event.preventDefault()
      setTouched(false)
      setSections(oldState)
    },
    [oldState]
  )

  useMessageBeforeUnload(touched)

  return (
    <div className="flex justify-center">
      <div style={{ width }}>
        <div className="flex justify-end items-center space-x-3 z-10 relative">
          <ProjectUpdated updatedAt={updatedAt} project={project} />
          <Button
            variant="tertiary"
            className="rounded-full"
            title="Share public link"
            style={{ height: 32, width: 32, padding: 0 }}
            onClick={() => {
              window.open(`/p/${project.token}`)
            }}
          >
            <Share />
          </Button>
        </div>

        {width && (
          <div className="flex justify-center" style={{ marginTop: -40 }}>
            <Hillchart
              ref={hillchartRef}
              width={width}
              theme={theme}
              tasks={sections}
              update={project.archived ? undefined : update}
            />
          </div>
        )}
        <div className="mt-6">
          {touched ? (
            <HistoryEntry
              touched={touched}
              width={width}
              project={project}
              sections={sections}
              snapshots={snapshots}
              hillchartRef={hillchartRef}
              onSave={(updatedProject) => {
                const { snapshots } = updatedProject
                ahoy.track('hillchart-update')
                setSnapshots(snapshots)
                setUpdatedAt(Date.now())
                setTouched(false)
                setAccount((account) => ({
                  ...account,
                  projects: account.projects.map((currProject) =>
                    project.id === currProject.id ? { ...project, last_update: snapshots[0] } : currProject
                  )
                }))
              }}
              onCancel={cancel}
            />
          ) : latestStatus ? (
            <StatusUpdate snapshot={latestStatus} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Hill
