import React from 'react'
import Sidebar from '@src/components/Sidebar'
import Button from '@src/components/Button'
import GridIcon from '@images/grid.svg'
import BeerIcon from '@images/beer.svg'
import SettingsIcon from '@images/settings.svg'
import NewTeamIcon from '@images/new_team.svg'
import AddIcon from '@images/add.svg'
import SignOutButton from '@src/sessions/SignoutButton'
import UserVoiceLink from '@src/pages/UserVoice'
import ButtonHint from '@src/layout/ButtonHint'
import { FormsContext } from '@src/App'

const AppSidebar = ({ docked, onClose }) => {
  const { setDrawer } = React.useContext(FormsContext)

  return (
    <Sidebar docked={docked} onClose={onClose}>
      <div className="flex-grow">
        <Button
          variant="sidebar"
          to="/"
          title="Projects and teams"
          onClick={() => {
            onClose()
          }}
        >
          <GridIcon width={28} />
          <ButtonHint>View projects, dasboards and teams</ButtonHint>
        </Button>
        <Button
          variant="sidebar"
          title="Create a new project"
          onClick={() => {
            setDrawer('project')
            onClose()
          }}
        >
          <AddIcon width={28} />
          <ButtonHint>Create a new project</ButtonHint>
        </Button>
        <Button
          variant="sidebar"
          title="Add a team"
          onClick={() => {
            setDrawer('team')
            onClose()
          }}
        >
          <NewTeamIcon width={28} />
          <ButtonHint>Add a team</ButtonHint>
        </Button>
      </div>

      <Button
        title="Buy me a beer!"
        variant="sidebar"
        onClick={() => {
          window.open('https://donate.stripe.com/14kdS00IJ5hQ8Mg9AB')
          onClose()
        }}
      >
        <BeerIcon width={28} />
        <ButtonHint>Buy me a beer!</ButtonHint>
      </Button>
      <UserVoiceLink />
      <Button
        title="Settings"
        variant="sidebar"
        to="/settings"
        onClick={() => {
          onClose()
        }}
      >
        <SettingsIcon width={28} />
        <ButtonHint>Preferences and subscription</ButtonHint>
      </Button>
      <SignOutButton />
    </Sidebar>
  )
}

export default AppSidebar
