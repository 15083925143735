import React, { useEffect, useState } from 'react'
import ahoy from 'ahoy.js'
import Input from '@src/components/Input'
import Label from '@src/components/Label'
import Button from '@src/components/Button'
import { useFetch } from 'react-async'

const PassInput = ({ value, paid }) => {
  const [show, setShow] = React.useState(false)
  return (
    <div className="flex items-center space-x-4 md:w-1/2">
      <Input name="api_key" className="font-mono" value={show ? value : '*'.repeat(value.length)} disabled />
      <Button
        variant="secondary"
        onClick={(event) => {
          event.preventDefault()
          setShow((show) => !show)
        }}
        disabled={!paid}
      >
        {show ? 'Hide' : 'Show'}
      </Button>
    </div>
  )
}

const EXAMPLE = `{
  "project": {
    "id": 281,
    "name": "Sample project"
  },
  "team": {
    "id": 21892,
    "name": "Rocket scientists"
  },
  "comment": "Mobile view is almost done.",
  "status": "on_track",
  "updated_by": {
    "id": 2901,
    "name": "Clark Kent"
  },
  "url": "https://www.hillia.app/projects/281/timeline",
  "updated_at": 1699644617
}`

export const CallbackForm = ({ team }) => {
  const [callbackUrl, setCallbackUrl] = useState(team.callback_url || 'https://')
  const { run } = useFetch(
    `/teams/${team.id}`,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'put'
    },
    {
      defer: true,
      onResolve: (team) => {
        window.location.reload()
      },
      onReject: console.error
    }
  )

  return (
    <>
      <div className="flex items-center mb-1">
        <h3 className="text-xl font-bold">Webhooks</h3>
      </div>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          run((init) => ({ ...init, body: JSON.stringify({ team: { callback_url: callbackUrl } }) }))
        }}
      >
        <div className="mb-3">
          To receive an alert when a Hill Chart is udated, you must configure a Webhook endpoint for your application.
          <br />
          The callback URL is associated with the team "{team.name}".
          <br />
        </div>

        <Label htmlFor="callback_url" name="callback_url" className="mb-2">
          Callback URL
        </Label>
        <div className="flex space-x-4 md:w-1/2">
          <Input
            id="callback_url"
            name="callback_url"
            autoComplete="off"
            disabled={!team.paid}
            className="mb-3"
            value={callbackUrl}
            onChange={(event) => {
              setCallbackUrl(event.target.value)
            }}
          />
          <Button variant="secondary" type="submit" disabled={!team.paid}>
            Save
          </Button>
        </div>

        <Label className="mb-2">Example - Callback payload:</Label>
        <code className="snippet mb-3">{EXAMPLE}</code>
      </form>
    </>
  )
}

export const RestApi = ({ project, team, apiKey }) => {
  const domain = window.environement === 'production' ? 'https://www.hillia.app' : 'http://localhost:3000'
  const url = project ? `${domain}/api/v1/projects/${project.id}/tasks` : `${domain}/api/v1/teams/${team.id}/projects`
  return (
    <>
      <div className="flex items-center mb-1">
        <h3 className="text-xl font-bold">REST API</h3>
      </div>
      <div className="mb-5">
        <span className="font-bold">Provide your secret API key</span> in the{' '}
        <code className="font-mono">HTTP_X_API_KEY</code> header of your requests.
      </div>

      <div className="mb-6">
        <Label className="mb-2" htmlFor="api_key">
          {project ? 'Your project API Key' : 'Your team API Key'}
        </Label>
        <PassInput value={apiKey} paid={team?.paid} />
      </div>

      <Label className="mb-2">
        Example - {project ? 'Fetching tasks of a project' : 'Fetching projects of a team'}
      </Label>
      <code className="snippet">
        {`curl -i -X GET \\
  -H "Content-Type: application/json" \\
  -H "X_API_KEY: ${apiKey}" \\
  ${url}`}
      </code>
      <a
        href="https://app.swaggerhub.com/apis-docs/Hil5/Hillia/1.3"
        target="_blank"
        className="underline color-green mb-4 text-right block mt-1"
      >
        Open API Documentation
      </a>
    </>
  )
}

export const Body = ({ project, team }) => {
  useEffect(() => {
    ahoy.track('project-options-show-api', { project_id: project.id })
  }, [])

  return (
    <>
      <RestApi project={project} apiKey={project.api_key} team={team} />
      <div className="mt-3">
        <CallbackForm team={team} />
      </div>
    </>
  )
}
