import { useFetch } from 'react-async'

function mapLocaleToCurrency(locale) {
  const currencyMap = {
    'en-US': 'USD',
    'ja-JP': 'JPY',
    'bg-BG': 'BGN',
    'cs-CZ': 'CZK',
    'da-DK': 'DKK',
    'en-GB': 'GBP',
    'hu-HU': 'HUF',
    'pl-PL': 'PLN',
    'ro-RO': 'RON',
    'sv-SE': 'SEK',
    'de-CH': 'CHF',
    'is-IS': 'ISK',
    'no-NO': 'NOK',
    'tr-TR': 'TRY',
    'en-AU': 'AUD',
    'pt-BR': 'BRL',
    'en-CA': 'CAD',
    'zh-CN': 'CNY',
    'zh-HK': 'HKD',
    'id-ID': 'IDR',
    'he-IL': 'ILS',
    'en-IN': 'INR',
    'ko-KR': 'KRW',
    'es-MX': 'MXN',
    'ms-MY': 'MYR',
    'en-NZ': 'NZD',
    'en-PH': 'PHP',
    'en-SG': 'SGD',
    'th-TH': 'THB',
    'en-ZA': 'ZAR'
  }

  const normalizedLocale = locale
  return currencyMap[normalizedLocale] || 'USD'
}

export default function useCurrencyConvertor({ locale, value }) {
  const currency = mapLocaleToCurrency(locale)
  const { data } = useFetch(`/stripe/currency_conversion?value=${value}&currency=${currency}`, {
    headers: { accept: 'application/json' }
  })
  return data?.value
    ? new Intl.NumberFormat(locale, {
        style: 'currency',
        currency
      }).format(data?.value)
    : undefined
}
