import React from 'react'
import dayjs from 'dayjs'
import { getStatusColor, STATUSES } from '@src/model/project'
import SnapshotOptions from '@src/pages/projects/SnapshotOptions'
import classNames from 'classnames'

export const StatusColor = ({ status, size = 'medium' }) => (
  <div
    className={classNames('rounded-full mr-3', {
      'h-2 w-2': size === 'medium',
      'h-3 w-3': size === 'large'
    })}
    style={{ backgroundColor: getStatusColor(status), minWidth: '0.5rem' }}
  />
)

const StatusUpdate = ({ snapshot, readOnly }) => {
  const { account, status, created_at, comment } = snapshot

  return (
    <div className="relative">
      {!readOnly && (
        <SnapshotOptions
          snapshot={snapshot}
          onUpdate={() => {
            window.location.reload()
          }}
          onDestroy={() => {
            window.location.reload()
          }}
        />
      )}
      <div className="flex items-center">
        <img src={account.image} title={account.name} className="rounded-full h-8 w-8 mr-3" />
        <div className="flex flex-col">
          <div className="font-bold mr-3">{account.name}</div>
          <div className="flex">
            <div className="text-gray-500">{dayjs(created_at).format('MMM D')}</div>
            {status && (
              <div className="flex items-center ml-2">
                <StatusColor status={status} />
                <div style={{ color: getStatusColor(status) }}>{STATUSES[status]}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      {comment && <div className="text-sm my-2 free-html" dangerouslySetInnerHTML={{ __html: comment }} />}
    </div>
  )
}

export default StatusUpdate
