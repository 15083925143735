import React from 'react'
import Label from '@src/components/Label'
import Input from '@src/components/Input'
import Select from '@src/components/Select'
import classNames from 'classnames'

const TYPE_MAPPING = {
  select: Select,
  text: Input,
  email: Input,
  password: Input
}

const Row = ({ name, label, margin = true, ...props }) => {
  const InputType = TYPE_MAPPING[props.type] || Input

  return (
    <div className={classNames({ 'mb-8': margin })}>
      {label && (
        <Label htmlFor={name} name={name} className="mb-2">
          {label}
        </Label>
      )}
      <InputType id={name} name={name} {...props} />
    </div>
  )
}

export default Row
