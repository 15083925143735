import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SlackIcon from '@images/slack.svg'
import Screenshot from '@images/slack-screenshot.png'
import Row from '@src/components/Row'
import Alert from '@src/components/Alert'
import TrashIcon from '@images/trash.svg'
import Button from '@src/components/Button'
import { useFetch } from 'react-async'
import useAccount from '@src/hooks/useAccount'
import sortBy from 'lodash/sortBy'
import EnterprisePill from '@src/pages/projects/EnterprisePill'
import { getSlackUrl } from '@src/model/project'

const Form = ({ project }) => {
  const navigate = useNavigate()
  const [slackChannel, setSlackChannel] = React.useState(project.slack_channel || '')
  const { data, isPending } = useFetch(`/projects/${project.id}/slack_channels.json`, {
    headers: { accept: 'application/json' }
  })
  const { run: save } = useFetch(
    `/projects/${project.id}`,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'put'
    },
    {
      defer: true,
      onResolve: () => {
        navigate(`/projects/${project.id}`)
      },
      onReject: console.error
    }
  )

  const { run: destroyConfiguration } = useFetch(
    `/projects/${project.id}/slack_config`,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'delete'
    },
    {
      defer: true,
      onResolve: () => {
        window.location.pathname = `/projects/${project.id}`
      },
      onReject: console.error
    }
  )

  const slackChannels = [
    {
      value: '',
      label: project.slack_channel ? 'No notifications' : 'Choose a channel',
      disabled: !project.slack_channel
    },
    ...sortBy(
      (data?.channels || []).map(({ id, name, is_private }) => {
        const prefix = is_private ? '🔒 ' : '#️⃣ '
        return {
          value: id,
          label: `${prefix}${name}`
        }
      }),
      ({ label }) => label
    )
  ]

  const onSubmit = (event) => {
    event.preventDefault()
    save((init) => ({
      ...init,
      body: JSON.stringify({ slack_channel: slackChannel })
    }))
  }

  const onReset = (event) => {
    event.preventDefault()
    if (window.confirm('Do you really want to reset the slack configuration for this project?')) {
      destroyConfiguration()
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="flex items-center">
        <SlackIcon height="20" width="20" />
        <span className="text-xl ml-2">Select a channel</span>
      </div>

      <div>
        <Alert className="mt-3 mb-3">
          <ul className="list-disc pl-2">
            <li>
              You must invite @Hillia to a <strong>private</strong> channel to enable notifications
            </li>
            <li>
              You must invite @Hillia to any channel to <strong>visualize Hills</strong> directly in the notifications
            </li>
          </ul>
          <div className="font-mono mt-1">/invite @hillia</div>
        </Alert>
      </div>
      <div className="flex items-center">
        <div className="block">
          <Row
            required={!project.slack_channel}
            type="select"
            style={{ minWidth: 300 }}
            disabled={isPending}
            options={slackChannels}
            name="slack_channel"
            autoComplete="off"
            value={slackChannel}
            onChange={(event) => {
              setSlackChannel(event.target.value)
            }}
          />
        </div>
        <Button type="submit" className="ml-2 mb-8" variant="secondary" disabled={isPending}>
          Save channel
        </Button>
      </div>

      <span className="text-xl my-3">Reset configuration</span>
      <div className="mt-1 mb-3">
        Reset your configuration if you want to change workspace or disable Slack notifications entirely.
      </div>
      <Button
        type="button"
        className="mr-3"
        variant="secondary"
        disabled={isPending}
        onClick={onReset}
        icon={TrashIcon}
      >
        Reset Slack configuration
      </Button>
    </form>
  )
}

const SlackValueProps = ({ team, account }) => {
  return (
    <div>
      <div className="flex items-center mb-6 flex-col md:flex-row">
        <h3 className="text-2xl font-bold montserrat">Slack notifications</h3>
        <div className="ml-2">
          <EnterprisePill />
        </div>
      </div>
      <div className="text-base">
        Stay in the loop and never miss a beat! Enable Slack notifications now to receive real-time project updates:
        embedded hill chart and written update.
      </div>
      <div className="font-bold mt-3 text-base">
        Your team is currently using a Free plan,{' '}
        {team.owner.id === account.id ? 'please ' : `please ask the owner of this team (${team.owner.name}) to `}{' '}
        <Link className="underline font-bold color-primary" to="/settings">
          subscribe to the Enterprise plan to enable Slack notifications.
        </Link>
      </div>
      <div className="flex justify-center mt-6">
        <div className="w-full lg:w-2/3">
          <img src={Screenshot} />
          <div className="text-center italic text-sm">Example of Hillia notification in Slack</div>
        </div>
      </div>
    </div>
  )
}

const ReloadRedirect = ({ project }) => {
  useEffect(() => {
    window.location = getSlackUrl(project)
  }, [])
  return null
}

const SlackConfig = () => {
  const { id } = useParams()
  const { data: project } = useFetch(`/projects/${id}.json`, {
    headers: { accept: 'application/json' }
  })
  const { account } = useAccount()

  if (!project) return null

  const team = account.teams.find((team) => team.id === project.team_id)

  if (team.paid) {
    if (project.slack_enabled) {
      return <Form project={project} />
    }

    return <ReloadRedirect project={project} />
  }

  return <SlackValueProps team={team} account={account} />
}

export default SlackConfig
