import React, { useContext } from 'react'
import SavingContext from '@src/hooks/SavingContext'
import ahoy from 'ahoy.js'
import { useFetch } from 'react-async'
import Popover, { PopoverItem } from '@src/components/Popover'
import Grip from '@images/grip.svg'
import Open from '@images/open.svg'
import Delete from '@images/trash.svg'
import Button from '@src/components/Button'
import Check from '@src/components/Check'
import { Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import throttle from 'lodash/throttle'
import './TaskListItem.css'

const TaskListItem = ({ task, members, onChange, onDelete, onEnter, onSelect, draggable, focused, readOnly }) => {
  const inputRef = React.useRef()
  const [, setSaved] = useContext(SavingContext)

  React.useEffect(() => {
    if (focused) {
      inputRef.current.focus()
    }
  }, [focused])

  const update = useFetch(
    `/projects/${task.project_id}/tasks/${task.id}`,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'put'
    },
    {
      defer: true,
      onReject: console.error,
      onResolve: ({ changed_saved }) => {
        if (changed_saved) {
          setSaved(true)
        }
      }
    }
  )

  const save = React.useCallback(
    throttle((attributes) => {
      update.run((init) => ({
        ...init,
        body: JSON.stringify({ task: attributes })
      }))
    }, 1000),
    []
  )

  const assign = (member) => {
    onChange({ ...task, account: member })
    save({ account_id: member.id })
  }

  const rename = (event) => {
    onChange({ ...task, name: event.target.value })
    save({ name: task.name })
  }

  const saveName = () => {
    save({ name: task.name })
  }

  const deleteTask = async () => {
    await fetch(`/projects/${task.project_id}/tasks/${task.id}`, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'delete'
    })

    setSaved(true)
    onDelete(task)
  }

  return (
    <div
      className={classNames('task-list-item flex items-center', {
        'task-list-item-completed': task.completed
      })}
      ref={draggable.innerRef}
      {...draggable.draggableProps}
    >
      <div {...draggable.dragHandleProps} style={{ width: 30 }}>
        <Grip className="task-list-item-grip mx-3" />
      </div>
      <div style={{ width: 40, padding: 6 }}>
        <Check
          checked={task.completed}
          onClick={() => {
            onChange({ ...task, completed: !task.completed })
            save({ completed: !task.completed })
          }}
        />
      </div>
      <div className="flex-grow">
        <div className="flex items-center pr-6">
          <input
            ref={inputRef}
            name={`task_${task.position}`}
            className="w-full py-2 pr-2"
            readOnly={readOnly}
            value={task.name}
            onBlur={() => {
              saveName()
            }}
            onChange={rename}
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                onEnter(task)
              }
              if (task.name === '') {
                deleteTask()
              }
            }}
          />

          <div className="flex items-center">
            <Button
              variant="quick-action"
              title="Open task"
              onClick={(event) => {
                ahoy.track('task-details-open', { task_id: task.id })
                event.preventDefault()
                onSelect(task)
              }}
            >
              <Open />
            </Button>

            <Button
              variant="quick-action"
              title="Delete task"
              onClick={(event) => {
                ahoy.track('task-delete', { task_id: task.id })
                event.preventDefault()
                deleteTask()
              }}
            >
              <Delete />
            </Button>

            <Popover
              position="left"
              button={({ onClick }) => (
                <button
                  className="flex items-center cursor-pointer ml-2 h-full"
                  title={task.account.name}
                  type="button"
                  onClick={(event) => {
                    onClick(event)
                    ahoy.track('task-assignee-click', { task_id: task.id })
                  }}
                >
                  <img src={task.account.image} className="rounded-full h-5 w-5 flex-shrink-0 mr-1" />
                </button>
              )}
            >
              {members.map((member) => (
                <PopoverItem
                  key={member.id}
                  icon={<img src={member.image} title={member.name} className="rounded-full h-6 w-6 mr-2" />}
                  onClick={() => {
                    assign(member)
                  }}
                >
                  {member.name}
                </PopoverItem>
              ))}
            </Popover>
          </div>
        </div>
      </div>
    </div>
  )
}

const DraggableTaskListItem = ({ index, ...props }) => (
  <Draggable draggableId={`task-${props.task.id}`} index={index} type="task">
    {(draggable) => <TaskListItem draggable={draggable} {...props} />}
  </Draggable>
)

export default DraggableTaskListItem
