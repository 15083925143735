import React from 'react'
import Row from '@src/components/Row'
import Button from '@src/components/Button'

const ContactForm = ({ onSuccess, onHide }) => {
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')

  const submit = (event) => {
    event.preventDefault()
    const form = event.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        onSuccess()
      }
    }
    xhr.send(data)
    alert('Message sent. Thank you !')
  }

  return (
    <form onSubmit={submit} action="https://formspree.io/mzbjvzgy" method="POST" className="mt-3">
      <Row
        label="Your email address"
        type="email"
        placeholder="email@domain.com"
        name="email"
        autoFocus
        required
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />

      <Row
        label="Your message"
        placeholder="Hello !"
        name="message"
        required
        multiline
        value={message}
        onChange={(event) => setMessage(event.target.value)}
      />

      <div className="flex justify-end">
        <Button
          onClick={(event) => {
            event.preventDefault()
            onHide()
          }}
          className="mr-3"
          variant="secondary"
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={!message || !email}>
          Send
        </Button>
      </div>
    </form>
  )
}

export default ContactForm
