import React from 'react'
import { useFetch } from 'react-async'
import shuffle from 'lodash/shuffle'
import SavingContext from '@src/hooks/SavingContext'

export const COLORS = [
  '#F44336',
  '#E91E63',
  '#009688',
  '#CDDC39',
  '#607D8B',
  '#673AB7',
  '#3F51B5',
  '#03A9F4',
  '#FF9800',
  '#F6BF26'
]

export const getRandomColor = (usedColors) => {
  const shuffledColors = shuffle(COLORS)
  let newColor
  if (usedColors) {
    newColor = shuffledColors.find((color) => !usedColors.includes(color))
  }
  if (newColor) {
    return newColor
  }
  return shuffledColors[0]
}

const DEFAULT_NAME = ''
const NewTask = ({ autoFocus, onSubmit, projectId, sectionId, position }, ref) => {
  const [name, setName] = React.useState(DEFAULT_NAME)
  const [, setSaved] = React.useContext(SavingContext)
  const { run } = useFetch(
    `/projects/${projectId}/tasks`,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'post'
    },
    {
      defer: true,
      onResolve: ({ changed_saved, ...task }) => {
        onSubmit({ ...task, name: task.name, isNew: true })
        setName(DEFAULT_NAME)
        if (changed_saved) {
          setSaved(true)
        }
      },
      onReject: console.error
    }
  )

  const saveTask = (event) => {
    event.preventDefault()
    if (name) {
      const color = getRandomColor()
      run((init) => ({
        ...init,
        body: JSON.stringify({
          task: {
            name,
            color,
            position,
            section_id: sectionId,
            progress: 0
          }
        })
      }))
    }
  }

  return (
    <div className="task-list-item flex items-center flex-grow">
      <div style={{ width: 30 }} />
      <div style={{ width: 40 }} />
      <form className="flex-grow relative flex" onSubmit={saveTask}>
        <input
          ref={ref}
          value={name}
          onChange={(event) => setName(event.target.value)}
          onBlur={saveTask}
          autoFocus={autoFocus}
          placeholder="Write a task name"
          className="flex-grow py-2"
        />
        <div className="task-list-item-keyboard-help">Press enter to save</div>
      </form>
    </div>
  )
}

export default React.forwardRef(NewTask)
