import React, { useContext } from 'react'
import CheckIcon from '@images/check.svg'
import Button from '@src/components/Button'
import SavingContext from '@src/hooks/SavingContext'
import { Link, useMatch } from 'react-router-dom'
import SlackLogo from '@images/slack-icon.png'
import ApiLogo from '@images/api.svg'
import useProject from '@src/hooks/useProject'
import classNames from 'classnames'
import './Tabs.css'

export const Tab = ({ to, onClick, children }) => {
  const match = useMatch(to)

  return (
    <div className={classNames('tab', { 'tab-selected': to && match })}>
      <Button component={to ? Link : undefined} to={to} onClick={onClick} variant="tab">
        {children}
      </Button>
    </div>
  )
}

const Tabs = () => {
  const { project } = useProject()
  const [saved] = useContext(SavingContext)

  return (
    <div className="tabs relative text-sm">
      <Tab to={`/projects/${project.id}`}>Hill</Tab>
      <Tab to={`/projects/${project.id}/timeline`}>Timeline</Tab>
      <Tab to={`/projects/${project.id}/api`}>
        <div className="flex items-center space-x-2">
          <ApiLogo title="" alt="" />
          <span>API & Webhooks</span>
        </div>
      </Tab>
      <Tab to={`/projects/${project.id}/slack`}>
        <div className="flex items-center space-x-2">
          <img width="18" src={SlackLogo} title="Slack" alt="Slack logo" />
          <span>Slack</span>
        </div>
      </Tab>

      {saved && (
        <div
          style={{
            position: 'absolute',
            right: '2em',
            top: '4px',
            display: 'flex',
            alignItems: 'center'
          }}
          className="text-green-500"
        >
          <CheckIcon style={{ height: 16, width: 16 }} className="mr-1" />
          Saved
        </div>
      )}
    </div>
  )
}

export default Tabs
