// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
  display: flex;
  flex: 1 0 0%;
  border-bottom: 1px solid #e6e9ef;
  font-family: 'Montserrat';
}

  .tabs::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1024px) {.tabs {
    overflow: auto
}
  }

  .tabs .tab {
    width: 170px;
    padding: 0 16px;
    border-bottom: 2px solid transparent;
  }

  .tabs .tab.tab-selected {
      font-weight: bold;
      border-color: var(--primary-color);
    }
`, "",{"version":3,"sources":["webpack://./app/javascript/src/pages/projects/Tabs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,gCAAgC;EAChC,yBAAyB;AAoB3B;;EAlBE;IACE,aAAa;EACf;;EAEA,4BAVF;IAWI;AAaJ;EAZE;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,oCAAoC;EAMtC;;EAJE;MACE,iBAAiB;MACjB,kCAAkC;IACpC","sourcesContent":[".tabs {\n  display: flex;\n  flex: 1 0 0%;\n  border-bottom: 1px solid #e6e9ef;\n  font-family: 'Montserrat';\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  @media (max-width: 1024px) {\n    overflow: auto;\n  }\n\n  .tab {\n    width: 170px;\n    padding: 0 16px;\n    border-bottom: 2px solid transparent;\n\n    &.tab-selected {\n      font-weight: bold;\n      border-color: var(--primary-color);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
