import React, { useState, useCallback } from 'react'
import { Stage, Layer, Group } from 'react-konva'
import { pow, e } from 'mathjs'
import useFontFamily from '@src/hooks/useFontFamily'
import Task from '@src/pages/projects/Task'
import { stack, SCALE_PRECISION } from '@src/model/task'
import throttle from 'lodash/throttle'
import { VerticalSeparator, Legend, Curve } from '@src/components/HillchartLayout'

export const PADDING = 20

export const xToProgress = (x, width) => Math.floor((x / (width - PADDING * 2)) * SCALE_PRECISION)
export const progressToX = (progress, width) => {
  return Math.ceil((progress / SCALE_PRECISION) * (width - PADDING * 2))
}

const createGaussian = (amplitude, x0, sigmaX) => (x) => {
  const exponent = -(pow(x - x0, 2) / (2 * pow(sigmaX, 2)))
  return amplitude * pow(e, exponent)
}

const Tasks = ({ tasks, gaussian, update, width, maxWidth, canvasRef }) => {
  const fontFamily = useFontFamily('Roboto')
  const [overredTask, setOverredTask] = useState()

  const updateTask = useCallback(
    throttle((taskId, attributes) => {
      update((tasks) => tasks.map((task) => (task.id === taskId ? { ...task, ...attributes, touched: true } : task)))
    }, 300),
    [update]
  )

  const onMouseOver = (id) => {
    setOverredTask(id)
  }
  const onMouseOut = () => {
    setOverredTask()
  }

  const stackedTasks = stack(width, overredTask)(tasks)
  return stackedTasks.map((task) => (
    <Task
      key={task.id}
      id={task.id}
      x={task.x}
      offset={task.offset}
      calculateY={gaussian}
      radius={task.radius}
      color={task.color}
      name={task.name}
      overred={task.overred}
      maxWidth={maxWidth}
      update={update ? updateTask : null}
      fontFamily={fontFamily}
      canvasRef={canvasRef}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    />
  ))
}

const Hillchart = ({ width, tasks, update }, ref) => {
  const fallbackRef = React.useRef()
  const canvasRef = ref || fallbackRef
  const height = width / 2
  const gaussian = useCallback((x) => height - createGaussian(height / 1.2, width / 2, width / 4)(x), [height, width])
  const contentWidth = width - PADDING

  return (
    <Stage width={width} height={height} ref={canvasRef}>
      <Layer>
        <Group>
          <VerticalSeparator gaussian={gaussian} height={height} width={width} />
          <Legend height={height} width={contentWidth} />
          <Curve gaussian={gaussian} width={contentWidth} />
        </Group>
        <Tasks
          gaussian={gaussian}
          tasks={tasks}
          update={update}
          width={width}
          maxWidth={contentWidth - PADDING}
          canvasRef={canvasRef}
        />
      </Layer>
    </Stage>
  )
}

export default React.forwardRef(Hillchart)
