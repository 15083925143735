import React from 'react'
// import { get } from '@src/Storage'
import './Layout.css'

export const ThemeContext = React.createContext()
export const useTheme = () => React.useContext(ThemeContext)

// const getDefaultTheme = () => 'light'

export const Layout = ({ children }) => {
  const defaultTheme = React.useRef('light')
  // const defaultTheme = React.useRef(get('theme') || getDefaultTheme())
  const [theme, setTheme] = React.useState(defaultTheme.current)

  React.useEffect(() => {
    document.querySelector('body').setAttribute('data-theme', theme)
  }, [theme])

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      <div className="wrapper">{children}</div>
    </ThemeContext.Provider>
  )
}
export const Main = ({ children }) => (
  <div className="cover-wrapper">
    <div className="content-wrapper">{children}</div>
  </div>
)
