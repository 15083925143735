import React, { useRef } from 'react'
import { useFetch } from 'react-async'
import Hillchart from '@src/components/Hillchart'
import Button from '@src/components/Button'
import { Link } from 'react-router-dom'
import PublicProject from '@src/pages/projects/PublicProject'
import useProject, { ProjectProvider } from '@src/hooks/useProject'
import StatusUpdate from '@src/pages/projects/StatusUpdate'
import { formatScopes } from '@src/model/task'
import Card, { CardContent, CardTitle } from '@src/components/Card'
import { StatusColor } from '../projects/StatusUpdate'
import { useMediaQuery } from 'react-responsive'

const LastHill = () => {
  const ref = useRef(null)
  const { project, setWidth, width } = useProject()
  const sections = formatScopes(project.sections, width)
  const lastSnapshot = project.snapshots.find(({ comment, status }) => comment || status)
  const isMediumScreen = useMediaQuery({ query: '(min-width: 768px)' })

  React.useEffect(() => {
    if (!ref.current) return
    const width = isMediumScreen ? ref.current.clientWidth / 2 : ref.current.clientWidth * 0.8

    setWidth(width)
  }, [isMediumScreen])

  return (
    <Card>
      <CardTitle>
        <div className="flex items-center justify-between">
          <div className="flex space-x-1 items-center">
            {lastSnapshot ? <StatusColor size="large" status={lastSnapshot.status} /> : null}
            <Link to={`/projects/${project.id}`}>{project.name}</Link>
          </div>
          <Button to={`/projects/${project.id}`} variant="outline">
            Open
          </Button>
        </div>
      </CardTitle>
      <hr className="mt-4" />
      <CardContent ref={ref}>
        <div className="flex flex-col md:flex-row space-y-6 md:space-x-6 md:space-y-0">
          <div>{width ? <Hillchart width={width} tasks={sections} /> : null}</div>
          {lastSnapshot ? (
            <div className="flex-1">
              <StatusUpdate snapshot={lastSnapshot} readOnly />
            </div>
          ) : null}
        </div>
      </CardContent>
    </Card>
  )
}

export default function DashboardProject({ projectId }) {
  return (
    <ProjectProvider id={projectId}>
      <LastHill />
    </ProjectProvider>
  )
}
