import React from 'react'
import { useNavigate } from 'react-router-dom'
import Dialog from '@src/components/Dialog'
import Row from '@src/components/Row'
import Input from '@src/components/Input'
import Button from '@src/components/Button'
import Label from '@src/components/Label'
import TeamPlanRequired from '@src/pages/teams/TeamPlanRequired'
import { useFetch } from 'react-async'
import uniq from 'lodash/uniq'
import useAccount from '@src/hooks/useAccount'
import { canInvite } from '@src/model/team'
import TeamIcon from '@images/team.svg'

const TeamForm = ({ show, team, onHide }) => {
  const [name, setName] = React.useState(team?.name || '')
  const [email, setEmail] = React.useState('')
  const navigate = useNavigate()
  const [members, setMembers] = React.useState([])
  const { account, setAccount } = useAccount()
  const creation = !team
  const { run } = useFetch(
    team ? `/teams/${team.id}` : '/teams',
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: team ? 'put' : 'post'
    },
    {
      defer: true,
      onResolve: (team) => {
        if (creation) {
          setAccount((account) => ({
            ...account,
            teams: [...account.teams, team]
          }))
          navigate(`/teams/${team.id}`)
        } else {
          setAccount((account) => ({
            ...account,
            teams: account.teams.map((currentTeam) => (currentTeam.id === team.id ? team : currentTeam))
          }))
        }
      },
      onReject: console.error
    }
  )

  const onSubmit = (event) => {
    event.preventDefault()
    onHide()
    run((init) => ({
      ...init,
      body: JSON.stringify({
        name,
        invites_attributes: members.map((member) => ({ email: member }))
      })
    }))
  }

  const addMember = () => {
    if (email) {
      setMembers((members) => uniq([...members, email]))
    }
  }

  const removeMember = (member) => {
    setMembers((members) => members.filter((email) => email !== member))
  }

  const dummyTeam = {
    owner: account,
    members: [...members, account],
    invites: [],
    paid: account.subscription.state === 'active'
  }
  const allowInvite = canInvite(dummyTeam)

  return (
    <Dialog
      show={show}
      onHide={onHide}
      title={
        <h2 className="flex items-center">
          <TeamIcon />
          <span className="pl-3 text-2xl">{creation ? 'Create a new team' : 'Update team'} </span>
        </h2>
      }
      position="right"
      padding
    >
      <Row
        label="Name of your team"
        name="name"
        autoComplete="off"
        autoFocus
        required
        value={name}
        onChange={(event) => setName(event.target.value)}
      />

      {!team && (
        <div className="mb-8">
          <Label>Members</Label>
          {members.map((member) => (
            <div key={member} className="py-3 flex items-center border-b border-gray-300">
              <div className="w-full">{member}</div>
              <Button
                onClick={() => {
                  removeMember(member)
                }}
              >
                -
              </Button>
            </div>
          ))}
          <form
            onSubmit={(event) => {
              event.preventDefault()
              addMember()
              setEmail('')
            }}
            className="flex py-3"
          >
            <Input
              placeholder="team_member@mail.com"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              disabled={!allowInvite}
            />
            <Button type="submit" variant="old-primary-flat" disabled={!allowInvite}>
              +
            </Button>
          </form>
        </div>
      )}

      <TeamPlanRequired team={team || dummyTeam} className="mb-4 -mt-4" />

      <div className="flex justify-end">
        <Button
          onClick={(event) => {
            event.preventDefault()
            onHide()
          }}
          className="mr-3"
          variant="secondary"
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={!name} onClick={onSubmit}>
          {creation ? 'Create ' : 'Update '} Team
        </Button>
      </div>
    </Dialog>
  )
}

export default TeamForm
