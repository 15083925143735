import React, { useMemo, useState } from 'react'
import EllipsisIcon from '@images/ellipsis-v.svg'
import { ListItem } from '@src/components/List'
import Trash from '@images/trash.svg'
import Popover, { PopoverItem } from '@src/components/Popover'
import { useFetch } from 'react-async'

export default function DashboardListItem({ dashboard }) {
  const { run: deleteDashboard } = useFetch(
    `/teams/${dashboard.team_id}/dashboards/${dashboard.id}`,
    { method: 'delete' },
    {
      defer: true,
      onReject: console.error,
      onResolve: () => {
        window.location.reload()
      }
    }
  )

  return (
    <ListItem
      key={dashboard.id}
      to={`/dashboards/${dashboard.id}`}
      title={`Open ${dashboard.name}`}
      options={
        <Popover
          position="left"
          button={({ onClick }) => (
            <button type="button" onClick={onClick} className="p-2" title={`Options for ${dashboard.name}`}>
              <EllipsisIcon />
            </button>
          )}
        >
          <PopoverItem
            icon={<Trash />}
            onClick={() => {
              if (window.confirm(`Delete dashboard "${dashboard.name}"? It will not be recoverable.`)) {
                deleteDashboard()
              }
            }}
          >
            Delete dashboard
          </PopoverItem>
        </Popover>
      }
    >
      {dashboard.name}
    </ListItem>
  )
}
