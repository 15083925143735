import React, { useState, useRef } from 'react'
import TaskList from '@src/pages/projects/TaskList'
import ProjectHeader from '@src/pages/projects/ProjectHeader'
import Hill from '@src/pages/projects/Hill'
import useProject, { ProjectProvider } from '@src/hooks/useProject'
import { Routes, Route, useMatch, Outlet, useParams } from 'react-router-dom'
import Timeline from '@src/pages/projects/Timeline'
import ApiTab from '@src/pages/projects/ApiTab'
import Tabs from '@src/pages/projects/Tabs'
import SavingContext from '@src/hooks/SavingContext'
import { getMetata, setMetadata } from '@src/Storage'
import { useMediaQuery } from 'react-responsive'
import SlackConfig from '@src/pages/projects/SlackConfig'
import { formatScopes } from '@src/model/task'
import useBraveWarning from './useBraveWarning'

const MAX_HILLCHART_WIDTH = 800 // Fullwidth on a macbook pro

const Project = () => {
  const isMediumScreen = useMediaQuery({ query: '(min-width: 768px)' })
  const chartContainer = useRef()
  const { project, setProject, width, setWidth } = useProject()
  const [saved, setSaved] = useState(false)
  const [tasks, setTasks] = useState([])
  const sections = project.sections

  const setSections = (sections) => {
    if (typeof sections === 'function') {
      setProject((project) => {
        const newSections = sections(project.sections)
        return {
          ...project,
          sections: newSections
        }
      })
    } else {
      setProject((project) => ({ ...project, sections }))
    }
  }
  const [hideCompleted, _setHideCompleted] = useState(getMetata(project).hideCompleted)
  const setHideCompleted = (newValue) => {
    setMetadata(project, { hideCompleted: newValue })
    _setHideCompleted(newValue)
  }

  const computeChartWidth = () => {
    const actualWidth = chartContainer.current?.clientWidth
    const width = actualWidth > MAX_HILLCHART_WIDTH ? MAX_HILLCHART_WIDTH : actualWidth
    if (width) {
      setSections(formatScopes(project.sections, width))
      setTasks(formatScopes(project.tasks))
      setWidth(width)
    }
  }

  React.useEffect(() => {
    computeChartWidth()
  }, [chartContainer.current])

  React.useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false)
      }, [4000])
    }
  }, [saved])

  useBraveWarning()

  const taskList = (
    <TaskList
      hideCompleted={hideCompleted}
      setHideCompleted={setHideCompleted}
      project={project}
      tasks={tasks}
      setTasks={setTasks}
      sections={sections}
      setSections={setSections}
    />
  )

  const content = (
    <div className="md:w-2/3 bg-white md:rounded-l-3xl p-6">
      <Tabs />
      <div className="py-6">
        <div className="relative" ref={chartContainer}>
          <Outlet context={{ width, sections, setSections, project }} />
        </div>
      </div>
    </div>
  )

  return (
    <div className="flex flex-col md:flex-row">
      <SavingContext.Provider value={[saved, setSaved]}>
        <div className="md:w-1/3 md:h-screen overflow-auto">
          <div className="p-6 ">
            <ProjectHeader />
          </div>
          {isMediumScreen ? taskList : content}
        </div>
        {isMediumScreen ? content : taskList}
      </SavingContext.Provider>
    </div>
  )
}

const ProjectFetch = () => {
  const { id: projectId } = useParams()
  return (
    <ProjectProvider id={Number(projectId)}>
      <Project />
    </ProjectProvider>
  )
}

export default ProjectFetch
