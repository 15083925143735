import React from 'react'
import { Link } from 'react-router-dom'
import EllipsisIcon from '@images/ellipsis-v.svg'
import Button from '@src/components/Button'
import useProject from '@src/hooks/useProject'
import ProjectDescription from './ProjectDescription'
import ProjectOptions from './ProjectOptions'

const ProjectHeader = ({ project: projectProp, readOnly }) => {
  const { project: projectContext } = useProject()
  const project = projectProp || projectContext
  const [name, setName] = React.useState(project.name)

  return (
    <div className="flex">
      <div className="w-full">
        <Link to={`/teams/${project.team.id}`} className="underline">
          {project.team.name}
        </Link>
        <div className="flex items-center mt-3">
          <h2 className="text-2xl montserrat flex items-center">{name}</h2>
          {readOnly ? null : (
            <ProjectOptions
              project={project}
              onChange={({ name }) => setName(name)}
              position={project.name.length > 15 ? 'left' : 'right'}
              button={({ onClick }) => (
                <Button
                  variant="tertiary"
                  title="Actions"
                  className="rounded-full ml-3 p-0"
                  style={{ height: 32, width: 32 }}
                  onClick={onClick}
                >
                  <EllipsisIcon />
                </Button>
              )}
            />
          )}
        </div>
        {readOnly ? null : <ProjectDescription projectId={project.id} defaultDescription={project.description} />}
        {project.archived && (
          <div className="text-base font-bold mt-3">This project has been archived and is now readonly.</div>
        )}
      </div>
    </div>
  )
}

export default ProjectHeader
