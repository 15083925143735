// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog {
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: var(--card-bg-color);
  transition: transform 200ms ease-in-out 0s;
  overflow: scroll;
}

  .dialog.dialog-top {
    top: 0;
    box-shadow: 0 2px 4px 0 rgba(--backgrop-gray-darker, 0.2);
    transform: translateY(-100%);
  }

  .dialog.dialog-right {
    top: 0;
    right: 0;
    width: 500px;
    max-width: 100%;
    height: 100vh;
    box-shadow: -2px 0px 4px 0 rgba(--backgrop-gray-darker, 0.2);
    transform: translateX(100%);
  }

  .dialog.dialog-bottom {
    bottom: 0;
    box-shadow: 0 -2px 4px 0 rgba(--backgrop-gray-darker, 0.2);
    transform: translateY(100%);
  }

  .dialog.dialog-entered {
    transform: translateY(0%) translateX(0%);
  }

  .dialog .dialog-padding {
    padding: 32px;
  }

.dialog-title {
  padding: 32px 32px 0;
}

.dialog-close {
  position: absolute;
  top: 6px;
  left: 0;
}
`, "",{"version":3,"sources":["webpack://./app/javascript/src/components/Dialog.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,WAAW;EACX,sCAAsC;EACtC,0CAA0C;EAC1C,gBAAgB;AA+BlB;;EA7BE;IACE,MAAM;IACN,yDAAyD;IACzD,4BAA4B;EAC9B;;EAEA;IACE,MAAM;IACN,QAAQ;IACR,YAAY;IACZ,eAAe;IACf,aAAa;IACb,4DAA4D;IAC5D,2BAA2B;EAC7B;;EAEA;IACE,SAAS;IACT,0DAA0D;IAC1D,2BAA2B;EAC7B;;EAEA;IACE,wCAAwC;EAC1C;;EAEA;IACE,aAAa;EACf;;AAGF;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,OAAO;AACT","sourcesContent":[".dialog {\n  position: fixed;\n  z-index: 1000;\n  width: 100%;\n  background-color: var(--card-bg-color);\n  transition: transform 200ms ease-in-out 0s;\n  overflow: scroll;\n\n  &.dialog-top {\n    top: 0;\n    box-shadow: 0 2px 4px 0 rgba(--backgrop-gray-darker, 0.2);\n    transform: translateY(-100%);\n  }\n\n  &.dialog-right {\n    top: 0;\n    right: 0;\n    width: 500px;\n    max-width: 100%;\n    height: 100vh;\n    box-shadow: -2px 0px 4px 0 rgba(--backgrop-gray-darker, 0.2);\n    transform: translateX(100%);\n  }\n\n  &.dialog-bottom {\n    bottom: 0;\n    box-shadow: 0 -2px 4px 0 rgba(--backgrop-gray-darker, 0.2);\n    transform: translateY(100%);\n  }\n\n  &.dialog-entered {\n    transform: translateY(0%) translateX(0%);\n  }\n\n  .dialog-padding {\n    padding: 32px;\n  }\n}\n\n.dialog-title {\n  padding: 32px 32px 0;\n}\n\n.dialog-close {\n  position: absolute;\n  top: 6px;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
