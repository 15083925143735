// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hillia-privacy p {
    margin: 0 0 14px;
  }
  .hillia-privacy h2 {
    margin: 14px 0 7px;
    font-size: 16px;
    font-weight: bold;
  }
`, "",{"version":3,"sources":["webpack://./app/javascript/src/pages/Privacy.css"],"names":[],"mappings":"AACE;IACE,gBAAgB;EAClB;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,iBAAiB;EACnB","sourcesContent":[".hillia-privacy {\n  p {\n    margin: 0 0 14px;\n  }\n\n  h2 {\n    margin: 14px 0 7px;\n    font-size: 16px;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
