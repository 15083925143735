import React from 'react'
import { Link } from 'react-router-dom'

export default function EnterprisePill() {
  return (
    <Link className="p-1 font-bold rounded bg-orange-300 uppercase text-xs" to="/settings">
      Enterprise plan required
    </Link>
  )
}
